import { z } from 'zod';

export const CurrentUser = z.object({
    accountNumber: z.string(),
    branding: z.unknown(),
    email: z.string(),
    enterpriseID: z.number(),
    firstName: z.string(),
    lastName: z.string(),
    operations: z.string().array(),
    userID: z.number(),
    userName: z.string(),
    userType: z.string(),
  });

export type CurrentUser = z.infer<typeof CurrentUser>;