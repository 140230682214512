import React from 'react';
import Button from '@mui/material/Button';

export interface ReleaseTenderButtonProps {
  title: string;
  isRetender?: boolean;
  disabled: boolean;
  onClick: () => void;
}

export function ReleaseTenderButton(props: ReleaseTenderButtonProps) {
  return (
    <Button
      data-qa="button-tender"
      variant="contained"
      title={props.title}
      disabled={props.disabled}
      style={{ margin: '5px' }}
      onClick={props.onClick}
    >
      {props.isRetender ? 'Re-Tender' : 'Release'}
    </Button>
  );
}
