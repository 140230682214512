import { CircularProgress } from "@mui/material";
import { Button, ButtonProps } from "../Button";

type LoadingButtonProps = ButtonProps & {
  /**
   * Toggle the button loading state
   */
  isLoading: boolean;
};

/**
 * Primary UI component for user interaction
 */
export const LoadingButton = ({ isLoading, ...props }: LoadingButtonProps) => {
  const disabled = isLoading;

  return isLoading ? (
    <Button {...props} disabled={disabled} color="inherit">
      <CircularProgress
        color="inherit"
        size={24}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    </Button>
  ) : (
    <Button {...props} />
  );
};
