import React from 'react';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';

export interface RefreshButtonProps {
  title: string;
  onClick: () => void;
}

export function RefreshButton(props: RefreshButtonProps) {
  return (
    <Button
      qa-data="button-refresh"
      variant="contained"
      style={{ margin: '5px' }}
      sx={{
        mx: 1,
        pr: 0,
      }}
      title={props.title}
      onClick={props.onClick}
      startIcon={<RefreshIcon style={{ fontSize: 30 }} />}
    />
  );
}
