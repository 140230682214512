import React from "react";
import ReactDOM from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";
import { App } from "./App";
import { AuthProvider } from "./auth";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

LicenseInfo.setLicenseKey(
  "71a74ffe99c9a2b385bdd54283aa19bdTz03MjM3MyxFPTE3MjMxNDU5NTcwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);
