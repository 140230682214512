import { StateCreator } from 'zustand';
import { AlertProps } from '@mui/material';

export type SnackbarSlice = {
    snackbar: Pick<AlertProps, "children" | "severity"> | null;
    setSnackbar: (snackbar: SnackbarSlice['snackbar']) => void;
    clearSnackbar: () => void;
}

export const createSnackbarSlice: StateCreator<SnackbarSlice, [], [], SnackbarSlice> = (set) => ({
  snackbar: null,
  setSnackbar: (newSnack) => set(() => ({ snackbar: newSnack })),
  clearSnackbar: () => set(() => ({ snackbar: null })),
});