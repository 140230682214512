import { Fax, Mail, Phone, Smartphone } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { RefCarrierContact } from '../../../../services/shipmentApi/models';

type CarrierContactDetailsProps = {
  refCarrierContact: RefCarrierContact;
};

export const CarrierContactDetails = ({
  refCarrierContact,
}: CarrierContactDetailsProps) => {
  return (
    <>
      <List>
        <ListItem>
          <Stack>
            <Tooltip title="Name">
              <Typography variant="h6" aria-label="contact name">
                {refCarrierContact.contact.name}
              </Typography>
            </Tooltip>
            <Tooltip title="Title">
              <Typography variant="body2" aria-label="contact title">
                {refCarrierContact.contact.title || '-'}
              </Typography>
            </Tooltip>
          </Stack>
        </ListItem>

        <ListItem>
          <Tooltip title="Type">
            <Typography variant="body1" aria-label="contact type">
              {refCarrierContact.contactType.type}
            </Typography>
          </Tooltip>
        </ListItem>

        <ListItem>
          <Tooltip title="Primary Phone">
            <ListItemIcon>
              <Phone />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={refCarrierContact.contact.phone || '-'} />
        </ListItem>

        <ListItem>
          <Tooltip title="Cell">
            <ListItemIcon>
              <Smartphone />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={refCarrierContact.contact.cell || '-'} />
        </ListItem>

        <ListItem>
          <Tooltip title="Fax">
            <ListItemIcon>
              <Fax />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={refCarrierContact.contact.fax || '-'} />
        </ListItem>

        <ListItem>
          <Tooltip title="Email">
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary={refCarrierContact.contact.email || '-'} />
        </ListItem>
      </List>
    </>
  );
};
