import React from 'react';
import Button from '@mui/material/Button';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { Columns } from '../../../models/columns';
import { GridColDef } from '@mui/x-data-grid-premium';

export interface ColumnSelectionModalProps {
    open: boolean;
    onClose: () => void;
    currentColumns: GridColDef[];
    onColumnChanged: (column: any) => void;
}

export function ColumnSelectionModal(props: ColumnSelectionModalProps) {

    function isChecked(propertyName: string) {
        return props.currentColumns.some(c => c.field === propertyName);
    }

    return (
        <Dialog
            open={props.open}
            title='Column Selection'
            style={{maxHeight: '90%'}}
        >
            <DialogTitle>Columns</DialogTitle>
            <DialogContent>
                {
                    Columns.map(c => {
                        return (
                            <div key={c.propertyName}>
                                <FormControlLabel
                                    label={c.title}
                                    control={<Checkbox checked={isChecked(c.propertyName)} onChange={() => props.onColumnChanged(c)} title={c.propertyName} />}
                                />
                            </div>
                        )
                    })
                }
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => props.onClose()} autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    );
}
