import { useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { RefCarrierSelectField } from '../../../components/fields/RefCarrierSelectField';
import { RefCarrier } from '../../../services/shipmentApi';
import { CarrierModeServiceList } from './CarrierModeServiceList';
import { CarrierContactsList } from './CarrierContactsList';

export const CarrierConfig = () => {
  const [selectedCarrier, setSelectedCarrier] = useState<RefCarrier | null>();

  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Carrier Configuration
      </Typography>
      <Box
        sx={{
          mb: 2,
          maxWidth: 432,
        }}
      >
        <RefCarrierSelectField
          helperText={'Select a carrier to configure'}
          onChange={(e, refCarrier) => setSelectedCarrier(refCarrier)}
          InputProps={{
            sx: {
              backgroundColor: 'background.paper',
            },
          }}
        />
      </Box>
      {selectedCarrier && (
        <Grid container spacing={2}>
          <Grid item>
            <Card sx={{ width: 'fit-content', minWidth: 400 }}>
              <CardContent>
                <CarrierModeServiceList
                  refCarrierId={selectedCarrier.refCarrierID}
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid item>
            <Card sx={{ width: 'fit-content', minWidth: 400 }}>
              <CardContent>
                <CarrierContactsList
                  refCarrierId={selectedCarrier.refCarrierID}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};
