import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { AccessDenied } from '../views/unauthorized/AccessDenied';
import { useAuth } from './AuthContext';
import { setPostLoginUri } from './utilities';

type RequireAuthProps = {
  children?: JSX.Element;
  isAdmin?: boolean;
}

export const RequireAuth = ({ children, isAdmin }: RequireAuthProps) => {


  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(false);

  const auth = useAuth();

  useEffect(() => {
    const serviceGetUser = async () => {
      let user = await auth.actions.getUser();
      if(user){
        setUserLoggedIn(true);
      }else{
        setPostLoginUri();
        auth.actions.login();
      }
    }
    serviceGetUser();
  })

  if(!auth.user){
    return null;
  }

  if(!auth.isValidUserType){
    return <AccessDenied />;
  }

  return children ? children : <Outlet />;
};
