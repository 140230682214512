import {
  default as MUIAutocomplete,
  autocompleteClasses,
  AutocompleteProps as MUIAutocompleteProps,
} from '@mui/material/Autocomplete';
import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Popper from '@mui/material/Popper';

import { Listbox, ListboxProps } from './ListBox';

export type AutocompleteProps<TOption, TMulti extends boolean = true> = Partial<
  Omit<MUIAutocompleteProps<TOption, TMulti, false, false>, 'onPaste'>
> & {
  label: string;
  helperText?: string;
  hidden?: boolean;
  loading?: boolean;
  errorMessage?: string;
  options?: TOption[];
  InputProps?: TextFieldProps['InputProps'];
};

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export const Autocomplete = <TOption extends any, TMulti extends boolean>({
  errorMessage,
  filterOptions,
  getOptionLabel,
  groupBy,
  helperText,
  isOptionEqualToValue,
  label,
  loading,
  multiple,
  onChange,
  onClose,
  onInputChange,
  onOpen,
  options,
  renderInput,
  InputProps,
}: AutocompleteProps<TOption, TMulti>) => {
  return (
    <MUIAutocomplete
      autoComplete
      disableCloseOnSelect
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      id={'enterprise_autocomplete_search'}
      isOptionEqualToValue={isOptionEqualToValue}
      ListboxComponent={Listbox}
      ListboxProps={{ getOptionLabel } as ListboxProps}
      loading={loading}
      multiple={multiple}
      onChange={onChange}
      onClose={onClose}
      onInputChange={onInputChange}
      onOpen={onOpen}
      options={options ?? []}
      // TODO Fix types on StyledPopper. Broke on @MUI upgrade...
      PopperComponent={StyledPopper as any}
      renderGroup={(params) => params as any}
      renderInput={
        renderInput ??
        ((params) => (
          <TextField
            {...params}
            error={Boolean(errorMessage)}
            helperText={errorMessage ?? helperText}
            label={label}
            sx={{ m: 0 }}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
            }}
          />
        ))
      }
      renderOption={(...args) => args as any}
    />
  );
};
