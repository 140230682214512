import Button from '@mui/material/Button';
import FilterOffIcon from '@mui/icons-material/FilterAltOff';

export interface ResetFiltersButtonProps {
  title: string;
  onClick: () => void;
}

export function ResetFiltersButton(props: ResetFiltersButtonProps) {
  return (
    <Button
      qa-data="button-reset-filters"
      variant="contained"
      style={{ margin: '5px' }}
      sx={{
        mx: 1,
        pr: 0,
      }}
      title={props.title}
      onClick={props.onClick}
      startIcon={<FilterOffIcon style={{ fontSize: 30 }} />}
    />
  );
}
