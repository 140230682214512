import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
} from '@mui/material';

export type ButtonProps = Pick<
  MuiButtonProps,
  | 'autoFocus'
  | 'children'
  | 'color'
  | 'disabled'
  | 'endIcon'
  | 'onClick'
  | 'size'
  | 'startIcon'
  | 'sx'
  | 'variant'
> & {
  /**
   * The button text to display
   */
  label: string;
  // variant: Extract<MuiButtonProps['variant'], 'contained'>;
};

/**
 * Primary UI component for user interaction
 */
export const Button = ({ label, children, ...props }: ButtonProps) => {
  return (
    <MuiButton variant="contained" {...props}>
      {label} {children}
    </MuiButton>
  );
};
