import React, { useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Button,
  Container,
  CssBaseline,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import GradingIcon from "@mui/icons-material/Grading";
import CancelIcon from "@mui/icons-material/Cancel";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import PendingIcon from "@mui/icons-material/Pending";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { NavLink, useLocation } from "react-router-dom";
import { MiscellaneousServices } from "@mui/icons-material";
import { PATHS, Router } from "../router";
import { ManualTenderBoard } from "../views/tender/manual-board";
import { PendingTenderBoard } from "../views/tender/pending-board";
import { TenderReviewBoard } from "../views/tender/review-board";
import { TenderRejectedBoard } from "../views/tender/rejected-board";
import { TenderCancelledBoard } from "../views/tender/cancelled-board";
import { useAuth, USER_ROLES } from "../auth";

const drawerWidth: number = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Routes = [
  {
    path: "/",
    sidebarName: "Pending",
    icon: <PendingIcon />,
    component: PendingTenderBoard,
  },
  {
    path: "/manual",
    sidebarName: "Manual",
    icon: <LocalShippingIcon />,
    component: ManualTenderBoard,
  },
  {
    path: "/tender-review",
    sidebarName: "Review",
    icon: <GradingIcon />,
    component: TenderReviewBoard,
  },
  {
    path: "/tender-rejected",
    sidebarName: "Rejected",
    icon: <AnnouncementIcon />,
    component: TenderRejectedBoard,
  },
  {
    path: "/tender-cancelled",
    sidebarName: "Cancelled",
    icon: <CancelIcon />,
    component: TenderCancelledBoard,
  },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function MainAppBar() {
  const auth = useAuth();
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  

  const toggleDrawer = () => {
    setOpen(!open);
  };

  // TODO: Move ThemeProvider to App.tsx
  
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex", height: "100%" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: "24px" }}>
            <IconButton
              data-qa="button-drawer-toggle"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              data-qa="header-title"
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Blueship Tender
            </Typography>
            <Button 
              data-qa="button-logout"
              onClick={auth.actions.logout} 
              color="inherit">
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            { auth.userHasOperation(USER_ROLES.CAN_MANAGE_TENDER_CONFIG) && (
              <>
                <ListItem component={NavLink} to={PATHS.ADMIN} disablePadding>
                  <ListItemButton selected={pathname === PATHS.ADMIN}>
                    <ListItemIcon>
                      <MiscellaneousServices />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </ListItemButton>
                </ListItem>
                <Divider />
              </>
            )}
            {Routes.map((prop, key) => (
              <ListItem
                component={NavLink}
                to={prop.path}
                key={key}
                disablePadding
              >
                <ListItemButton selected={pathname === prop.path}>
                  <ListItemIcon children={prop.icon} />
                  <ListItemText primary={prop.sidebarName} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            display: "flex",
            paddingTop: "50px",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100%",
            overflow: "auto",
          }}
        >
          <Toolbar style={{ padding: 0 }} />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <Router />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default MainAppBar;
