import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import MainAppBar from './components/menuBarNew';
import { GlobalSnackbar } from './components/GlobalSnackbar';
import { GlobalDialog } from './components/GlobalDialog';

const queryClient = new QueryClient();

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <MainAppBar />
      </BrowserRouter>
      <GlobalSnackbar />
      <GlobalDialog />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
  );
}
