import { useMemo, useState } from 'react';
import { z } from 'zod';
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import {
  NewRefCarrierTender,
  RefCarrierModeServiceOption,
  RefCarrierTender,
  TenderTypeList,
  TenderTypes,
} from '../../../../../services/shipmentApi';
import { Button } from '../../../../../stories/Button';
import { LoadingButton } from '../../../../../stories/LoadingButton';

type TenderFormProps = {
  isReadOnly: boolean;
  isSaving: boolean;
  onSave: (tender: RefCarrierTender | NewRefCarrierTender) => void;
  /** called after cancel logic is run */
  onCancel: () => void;
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID'];
  tenderDetails: RefCarrierTender | null;
};

export const TenderForm = ({
  refCarrierModeServiceId,
  tenderDetails,
  ...rest
}: TenderFormProps) => (
  <TenderDetails
    key={`${refCarrierModeServiceId}-${tenderDetails?.refCarrierTenderID}`}
    refCarrierModeServiceId={refCarrierModeServiceId}
    tenderDetails={tenderDetails}
    {...rest}
  />
);

type ReadOnlyFieldProps = {
  name: string;
  value: string;
};

const ReadOnlyField = ({ name, value }: ReadOnlyFieldProps) => (
  <Box sx={{ ml: 1, mb: 1 }}>
    <Typography variant="body2">{name}</Typography>
    <Typography variant="body1" gutterBottom>
      {value}
    </Typography>
  </Box>
);

const TenderDetails = ({
  isReadOnly,
  isSaving,
  onCancel,
  onSave,
  tenderDetails,
}: TenderFormProps) => {
  const initialFormValues = useMemo(
    () =>
      tenderDetails
        ? tenderDetails
        : {
            type: TenderTypes.None,
            connectionAddress: '',
            isAutoAccept: false,
            password: '',
            username: '',
          },
    [tenderDetails]
  );

  const [tender, setTender] =
    useState<RefCarrierTender | NewRefCarrierTender>(initialFormValues);
  const [fieldErrors, setFieldErrors] =
    useState<ReturnType<z.ZodError['flatten']>['fieldErrors']>();

  const isSaveDisabled = useMemo(() => {
    if (tender.type === TenderTypes.Email) {
      return !tender.connectionAddress;
    }

    if (tender.type === TenderTypes.EDI) {
      return !(tender.connectionAddress && tender.username && tender.password);
    }

    return false;
  }, [tender]);

  return (
    <Box sx={{ width: 400 }}>
      <Stack direction="column">
        {/* TENDER TYPE */}
        {isReadOnly ? (
          <ReadOnlyField name="Type" value={tender.type} />
        ) : (
          <FormControl sx={{ mb: 3 }}>
            <InputLabel id="tender-type-label">Type</InputLabel>
            <Select
              disabled={isReadOnly}
              labelId="tender-type-label"
              id="tender-type"
              value={tender.type}
              label="Type"
              onChange={(e) => {
                if (!e.target.value) return;
                setTender({
                  ...initialFormValues, // reset all values when toggling between tender types
                  type: e.target.value as any,
                });
                setFieldErrors(undefined); // reset form errors
              }}
            >
              {TenderTypeList.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* AUTO ACCEPT */}
        {tender.type !== TenderTypes.None &&
          (isReadOnly ? (
            <ReadOnlyField
              name="Is Auto Accept"
              value={tender.isAutoAccept ? 'Yes' : 'No'}
            />
          ) : (
            <FormControl sx={{ mb: 3 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      disabled={isReadOnly}
                      checked={tender.isAutoAccept}
                      onChange={(e) =>
                        setTender({
                          ...tender,
                          isAutoAccept: e.target.checked,
                        })
                      }
                    />
                  }
                  label="Is Auto Accept"
                />
              </FormGroup>
            </FormControl>
          ))}

        {/* EDI FIELDS */}
        {tender.type === TenderTypes.EDI && (
          <>
            {/* CONNECTION ADDRESS */}
            {isReadOnly ? (
              <ReadOnlyField
                name="Connection Address"
                value={tender.connectionAddress}
              />
            ) : (
              <FormControl sx={{ mb: 3 }}>
                <TextField
                  error={Boolean(fieldErrors?.connectionAddress)}
                  value={tender.connectionAddress}
                  onChange={(e) =>
                    setTender({
                      ...tender,
                      connectionAddress: e.target.value,
                    })
                  }
                  label="Connection Address"
                  helperText={fieldErrors?.connectionAddress?.join('; ')}
                />
              </FormControl>
            )}

            {/* USERNAME */}
            {isReadOnly ? (
              <ReadOnlyField name="User Name" value={tender.username} />
            ) : (
              <FormControl sx={{ mb: 3 }}>
                <TextField
                  error={Boolean(fieldErrors?.username)}
                  value={tender.username}
                  onChange={(e) =>
                    setTender({ ...tender, username: e.target.value })
                  }
                  label="Username"
                  helperText={fieldErrors?.username?.join('; ')}
                />
              </FormControl>
            )}

            {/* PASSWORD */}
            {isReadOnly ? (
              <ReadOnlyField name="Password" value={tender.password} />
            ) : (
              <FormControl sx={{ mb: 3 }}>
                <TextField
                  error={Boolean(fieldErrors?.password)}
                  value={tender.password}
                  onChange={(e) =>
                    setTender({ ...tender, password: e.target.value })
                  }
                  label="Password"
                  helperText={fieldErrors?.password?.join('; ')}
                />
              </FormControl>
            )}
          </>
        )}

        {/* EMAIL ADDRESS */}
        {tender.type === TenderTypes.Email &&
          (isReadOnly ? (
            <ReadOnlyField
              name="Email Address"
              value={tender.connectionAddress}
            />
          ) : (
            <FormControl sx={{ mb: 3 }}>
              <TextField
                error={Boolean(fieldErrors?.connectionAddress)}
                value={tender.connectionAddress}
                onChange={(e) =>
                  setTender({
                    ...tender,
                    connectionAddress: e.target.value,
                  })
                }
                label="Email Address"
                name="email"
                helperText={fieldErrors?.connectionAddress?.join('; ')}
              />
            </FormControl>
          ))}

        {!isReadOnly && (
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
          >
            <LoadingButton
              disabled={isSaveDisabled}
              isLoading={isSaving}
              label="Save Tender"
              onClick={async () => {
                try {
                  onSave(tender);
                } catch (e) {
                  if (e instanceof z.ZodError) {
                    setFieldErrors(e.flatten().fieldErrors);
                  }
                }
              }}
              startIcon={<SaveIcon />}
            />
            <Button
              label="Cancel"
              onClick={() => {
                onCancel && onCancel();
              }}
              startIcon={<ClearIcon />}
              variant="outlined"
            />
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
