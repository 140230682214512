import { z } from "zod";

export const EnterpriseConfiguration = z.object({
  applyCustomerPriceMarkup: z.boolean(),
  autoAssignProstatus: z.string(),
  bypassTender: z.boolean(),
  carrierInvoiceNegVariance: z.number(),
  carrierInvoicePosVariance: z.number(),
  customerInvoiceThreshold: z.number(),
  enterpriseConfigurationID: z.number(),
  enterpriseID: z.number(),
  insuranceCustomerChargeMinValue: z.number(),
  insuranceCustomerMarkup: z.number(),
  insuranceRequestEmail: z.string().nullable(),
  isBlueShipBilling: z.boolean(),
  isBlueShipTender: z.boolean(),
  isEmailInsurance: z.boolean(),
  isEmailTlvolume: z.boolean(),
  isGeneratePrimaryReference: z.boolean(),
  isHouseAccount: z.boolean(),
  isMasterBill: z.boolean(),
  isOpenBook: z.boolean(),
  isOwnFreightDesk: z.boolean(),
  isProActive: z.boolean(),
  isWhiteList: z.boolean(),
  legalName: z.string(),
  masterBillAccountNumber: z.string().nullable(),
  mgdownMarkup: z.number(),
  needsTenderReview: z.boolean(),
  numberRatesToDisplay: z.number(),
  operationsEmail: z.string(),
  operationsPhone: z.string(),
  pendingShipmentNotificationEmail: z.string().nullable(),
  proActiveAuditNumber: z.string().nullable(),
  rateRequestNotificationEmail: z.string().nullable(),
  shipmentErrorEmailRecipients: z.string().nullable(),
  shipmentNotificationEmail: z.string().nullable(),
  showCorporateTl: z.boolean(),
  specialServicesEmail: z.string(),
  specialServicesPhone: z.string(),
  techErrorEmailRecipients: z.string().nullable(),
  tlvolumeEmail: z.string().nullable(),
  tmsid: z.string(),
  tmskey: z.string(),
  truckloadManagerEmail: z.string().nullable(),
  truckloadRateMarkupPercent: z.number(),
  volumeCarrierMinValue: z.number(),
  volumeRateMarkupPercent: z.number(),
  website: z.string().nullable(),
});

export type EnterpriseConfiguration = z.infer<typeof EnterpriseConfiguration>;
