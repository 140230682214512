// TODO: Used here as just an example.
// To make this a standard component:
// [] Refactor out any domain-specific or use-case specific implementations
// [] Refactor dependent components into additional core components, tightly coupled sibling comopnents, or generic render slots
// [] One component per file - split this apart!

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  DataGridPremium,
  GridColDef,
  GridFilterModel,
  GridRowGroupingModel,
  GridColumnOrderChangeParams,
  GridRowSelectionModel,
  DataGridPremiumProps,
} from '@mui/x-data-grid-premium';
import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
/** R */ import {
  ColumnSelectionModal,
  ColumnSelectionModalProps,
} from '../../components/controls/modals/columnSelectionModal';

import { makeStyles } from 'tss-react/mui';
/** R */ import { RefreshButton } from '../../components/controls/buttons/refreshButton';
/** R */ import { ResetFiltersButton } from '../../components/controls/buttons/resetFiltersButton';
/** R */ import { ResetRowGroupsButton } from '../../components/controls/buttons/resetRowGroupsButton copy';
import React from 'react';

const useStylesBoard = makeStyles()((theme) => {
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    innerRoot: {
      width: '100%',
    },
    innerBox: {
      display: 'flex',
      margin: 0,
    },
    innerInnerBox: {
      display: 'flex',
      flexGrow: 1,
      padding: 1,
      margin: 0,
    },
    titleContainer: {},
    buttonContainer: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: '5px',
    },
    button: {},
    backdrop: {
      color: '#fff',
    },
  };
});

const useStylesDataGrid = makeStyles()((theme) => {
  return {
    root: {
      boxShadow: '2px 2px 10px 3px rgba(0,46,109,0.15)',
      overflow: 'hidden',
      '& *': {
        outline: 'none !important',
      },
      '& .MuiDataGrid-footerContainer': {
        background:
          'linear-gradient(0deg, rgba(0,0,0,35%) 0%, rgba(255,255,255,100%) 100%)',
        border: 0,
      },
    },
    headers: {
      background: '#1976d2',
      color: 'white',
      textShadow: '1px 2px rgb(0 0 0 / 15%)',
    },
    perspective: {
      perspective: '1000px',
    },
    row: {
      transition: 'transform ease-in-out 350ms',
      transformOrigin: 'center center',
      transform: 'rotateX(0deg)',
      '&.Mui-selected': {
        transform: 'rotateX(360deg)',
      },
      '&:hover': {
        background:
          'linear-gradient(0deg, rgba(0,154,221,0.12) 0%, rgba(255,255,255,0) 33%)',
      },
    },
    cell: {
      '&:focus': {
        outline: 'none',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
      '& input:focus': {
        outline: 'none',
      },
    },
  };
});

export interface StandardBoardProps {
  title?: string; // Default Tender Board
  titleComponent?: ReactElement; // Tpography
  isColumnsButtonVisible?: boolean; // true
  onColumnButtonClick?: () => void;
  isColumnsDialogOpen?: boolean;
  onColumnsDialogClose?: () => void;
  onColumnsDialogChanged?: ColumnSelectionModalProps['onColumnChanged'];
  primaryElement?: ReactElement;
  secondaryElement?: ReactElement;
  isBackdropVisible?: boolean; // false;
  columns: GridColDef[];
  children?: ReactNode;
  classes?: ReturnType<typeof useStylesBoard>['classes'];
  onRefreshButtonClick?: () => Promise<any>;
  dataGridProps: {
    filterStorageObject?: string;
    groupingStorageObject?: string;
    onColumnIndexChange?: (
      columnPropertyName: string,
      targetIndex: number
    ) => void;
    filterModel?: GridFilterModel;
    rowGroupingModel?: GridRowGroupingModel;
    onFilterModelChange?: (model: GridFilterModel) => void;
    onRowGroupingModelChange?: (model: GridRowGroupingModel) => void;
  } & DataGridPremiumProps;
}

export function Board(props: StandardBoardProps) {
  const currentProps: StandardBoardProps = {
    title: 'Default Board',
    isColumnsButtonVisible: true,
    isBackdropVisible: false,
    ...props,
  };
  const { classes: stylesBoardClasses } = useStylesBoard(undefined, {
    props: { classes: currentProps.classes! },
  });
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const [isColumnsDialogOpenState, setIsColumnsDialogOpenState] = useState(
    currentProps.isColumnsDialogOpen || false
  );
  const isColumnsDialogOpen =
    currentProps.isColumnsDialogOpen !== undefined
      ? currentProps.isColumnsDialogOpen
      : isColumnsDialogOpenState;
  const emptyFilters: GridFilterModel = {
    items: [],
  };
  const [filters, setFilters] = useState<GridFilterModel>(emptyFilters);
  const [rowGrouping, setRowGrouping] = useState<GridRowGroupingModel>();
  const filterStorageObject = props?.dataGridProps?.filterStorageObject;
  const groupingStorageObject = props?.dataGridProps?.groupingStorageObject;

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50, // default page size
    page: 0,     // default page
  });

  useEffect(() => {
    function loadFilters() {
      if (filterStorageObject && localStorage.getItem(filterStorageObject)) {
        let obj = localStorage.getItem(filterStorageObject);
        if (obj) {
          let gfm = JSON.parse(obj) as GridFilterModel;
          setFilters(gfm);
        }
      }
    }

    loadFilters();
  }, [filterStorageObject]);

  useEffect(() => {
    if (groupingStorageObject) {
      const obj = localStorage.getItem(groupingStorageObject);
      if (obj) {
        const grouping = JSON.parse(obj) as GridRowGroupingModel;
        setRowGrouping(grouping);
      }
    }
  }, [groupingStorageObject]);

  function onColumnButtonClick() {
    if (props.onColumnButtonClick) {
      props.onColumnButtonClick();
    } else {
      setIsColumnsDialogOpenState(true);
    }
  }

  function onColumnsDialogClose() {
    if (props.onColumnsDialogClose) {
      props.onColumnsDialogClose();
    } else {
      setIsColumnsDialogOpenState(false);
    }
  }

  async function onRefreshButtonClick() {
    if (currentProps.onRefreshButtonClick) {
      setIsBackdropVisible(true);
      try {
        await currentProps.onRefreshButtonClick();
      } finally {
        setIsBackdropVisible(false);
      }
    }
  }

  function handleFilterModelChange(gfm: GridFilterModel) {
    let filterJson = JSON.stringify(gfm);
    if (filterStorageObject) {
      localStorage.setItem(filterStorageObject, filterJson);
    }

    setFilters(gfm);
  }

  function handleRowGroupingModelChange(grouping: GridRowGroupingModel) {
    setRowGrouping(grouping);
    if (groupingStorageObject) {
      localStorage.setItem(groupingStorageObject, JSON.stringify(grouping));
    }
  }

  const primaryElement = currentProps.primaryElement ? (
    <Fragment>
      <Divider
        className={stylesBoardClasses.divider}
        style={{ margin: '5px' }}
        orientation="vertical"
      />
      {currentProps.primaryElement}
    </Fragment>
  ) : undefined;

  const secondaryElement =
    currentProps.secondaryElement || currentProps.onRefreshButtonClick ? (
      <Fragment>
        <Divider
          className={stylesBoardClasses.divider}
          style={{ margin: '5px' }}
          orientation="vertical"
        />
        {filters && filters.items && filters.items.length > 0 ? (
          <ResetFiltersButton
            title="Reset all filters."
            onClick={() => handleFilterModelChange(emptyFilters)}
          />
        ) : undefined}
        {rowGrouping && rowGrouping.length > 0 ? (
          <ResetRowGroupsButton
            title="Reset all row grouping."
            onClick={() => handleRowGroupingModelChange([])}
          />
        ) : undefined}
        {currentProps.secondaryElement ? (
          currentProps.secondaryElement
        ) : currentProps.onRefreshButtonClick ? (
          <RefreshButton
            title="Refresh the current report list"
            onClick={onRefreshButtonClick}
          />
        ) : undefined}
      </Fragment>
    ) : undefined;

  const [pageSize, setPageSize] = useState<number>(50);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>();

  function handleColumnOrderChange(params: GridColumnOrderChangeParams) {
    if (props.dataGridProps.onColumnIndexChange) {
      props.dataGridProps.onColumnIndexChange(
        params.column.field,
        params.targetIndex
      );
    }
  }

  const { classes: dataGridClasses } = useStylesDataGrid();

  return (
    <div className={stylesBoardClasses.root}>
      <div className={stylesBoardClasses.innerRoot}>
        <Box className={stylesBoardClasses.innerBox}>
          <Box className={stylesBoardClasses.innerInnerBox}>
            <div className={stylesBoardClasses.titleContainer}>
              {currentProps.titleComponent ? (
                currentProps.titleComponent
              ) : (
                <Typography variant="h5">{currentProps.title}</Typography>
              )}
            </div>
          </Box>
          <Box className={stylesBoardClasses.buttonContainer}>
            {currentProps.isColumnsButtonVisible ? (
              <Button
                className={stylesBoardClasses.button}
                onClick={onColumnButtonClick}
              >
                Columns
              </Button>
            ) : undefined}
            {primaryElement}
            {secondaryElement}
          </Box>
        </Box>
      </div>
      <Box sx={{ height: '100%', width: '100%' }}>
        <DataGridPremium
          autoHeight
          classes={{
            root: dataGridClasses.root,
            columnHeaders: dataGridClasses.headers,
            row: dataGridClasses.row,
            cell: dataGridClasses.cell,
            virtualScrollerRenderZone: dataGridClasses.perspective,
          }}
          paginationModel={paginationModel}
          onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
          pageSizeOptions={[50, 100]}
          pagination={true}
          rowSelectionModel={selectedRows}
          onColumnOrderChange={handleColumnOrderChange}
          disableRowSelectionOnClick
          {...props.dataGridProps}
        />
      </Box>
      <ColumnSelectionModal
        open={!!isColumnsDialogOpen}
        currentColumns={currentProps.columns}
        onClose={onColumnsDialogClose}
        onColumnChanged={props.onColumnsDialogChanged || (() => { })}
      />
      {currentProps.children}
      <Backdrop
        className={stylesBoardClasses.backdrop}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackdropVisible || !!currentProps.isBackdropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
