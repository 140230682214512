import { useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '../../../../stories/Button';
import { LoadingButton } from '../../../../stories/LoadingButton';
import {
  NewRefCarrierTender,
  RefCarrierModeServiceOption,
  RefCarrierTender,
  TenderTypes,
  useAddRefCarierModeServiceTenderMutation,
  useDeleteRefCarierTenderMutation,
  useGetRefCarierModeServiceTender,
  useUpdateRefCarierTenderMutation,
} from '../../../../services/shipmentApi';
import { useStore } from '../../../../store';
import { TenderForm } from './TenderForm';
import { SkeletonLoader } from '../../../../stories/SkeletonLoader';

type CarrierServiceTenderProps = {
  modeService: RefCarrierModeServiceOption;
};

export const CarrierServiceTenderList = ({
  modeService,
}: CarrierServiceTenderProps) => {
  const { data: tender, isLoading } = useGetRefCarierModeServiceTender(
    modeService.refCarrierModeServiceID
  );
  const addRefCarrierTenderMutation = useAddRefCarierModeServiceTenderMutation(
    modeService.refCarrierModeServiceID
  );
  const updateRefCarrierTenderMutation = useUpdateRefCarierTenderMutation(
    modeService.refCarrierModeServiceID
  );
  const refCarrierTenderDeleteMutation = useDeleteRefCarierTenderMutation(
    modeService.refCarrierModeServiceID
  );
  const [showTenderForm, setShowTenderForm] = useState(false);

  const setSnack = useStore((state) => state.setSnackbar);
  const setDialog = useStore((state) => state.setDialog);

  const resetTenderState = () => {
    setShowTenderForm(false);
  };

  const handleOnSave = async (
    tender: RefCarrierTender | NewRefCarrierTender
  ) => {
    if (tender.type === TenderTypes.None) {
      // Handles case where someone manually sets a saved Tender's type to "None",
      // instead of using the delete button.
      await handleDeleteTender();
      resetTenderState();
      return;
    }

    if (tender.refCarrierTenderID) {
      const result = RefCarrierTender.parse(tender);
      await updateRefCarrierTenderMutation.mutateAsync(result);
    } else {
      const result = NewRefCarrierTender.parse(tender);
      await addRefCarrierTenderMutation.mutateAsync(result);
    }

    setSnack({
      children: 'Tender successfully saved',
      severity: 'success',
    });

    resetTenderState();
  };

  const handleDeleteTender = async () => {
    try {
      await refCarrierTenderDeleteMutation.mutateAsync(
        tender!.refCarrierTenderID
      );
      setSnack({
        children: 'Tender successfully deleted',
        severity: 'success',
      });
    } catch (e) {
      console.error(e); // TODO: Logging
      setSnack({
        children: `There was an error attempting to delete selected Tender`,
        severity: 'error',
      });
    }
  };

  const confirmDelete = () => {
    setDialog({
      content: <>Do you really want to delete this tender?</>,
      onCancel: () => {
        /* NOOP */
      },
      onConfirm: () => handleDeleteTender(),
    });
  };

  const isSaving =
    addRefCarrierTenderMutation.isLoading ||
    updateRefCarrierTenderMutation.isLoading;

  return (
    <Box sx={{ mt: 6 }}>
      {isLoading ? (
        <>
          <SkeletonLoader variant="rectangular" height={60} />
          <SkeletonLoader variant="rectangular" height={60} />
          <SkeletonLoader variant="rectangular" height={60} />
        </>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Tender for {modeService.modeService}
          </Typography>

          <TenderForm
            isReadOnly={!showTenderForm}
            isSaving={isSaving}
            onSave={handleOnSave}
            onCancel={resetTenderState}
            refCarrierModeServiceId={modeService.refCarrierModeServiceID}
            tenderDetails={tender || null}
          />

          {!showTenderForm && (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Button
                label="Edit"
                onClick={() => setShowTenderForm(!showTenderForm)}
                startIcon={<EditIcon />}
                sx={{ mt: 1 }}
                variant="text"
              />
              <LoadingButton
                color="error"
                disabled={!tender?.refCarrierTenderID}
                isLoading={refCarrierTenderDeleteMutation.isLoading}
                label="Delete"
                onClick={confirmDelete}
                startIcon={<RemoveIcon />}
                sx={{ mt: 1 }}
                variant="text"
              />
            </Stack>
          )}
        </>
      )}
    </Box>
  );
};
