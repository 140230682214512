import { Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Page } from '../../components/Page';

export const AccessDenied = () => <Page render={() => (
  <Box sx={{ width: '100%' }}>
      <Stack alignItems="center">
        <ErrorIcon
          sx={{
            fontSize: '80px',
            color: 'error.dark',
          }}
          fontSize="large"
        />
        <Typography variant="h5">
          You are not authorized to access this resource.
        </Typography>
      </Stack>
    </Box>
)} />;
