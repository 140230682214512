import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';

const defaultRender = () => null;

type PageProps = {
  render: () => React.ReactElement | null;
};

export const Page = ({ render = defaultRender }: PageProps) => {
  return (
    <>
      <Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          {render()}
        </Paper>
      </Container>
    </>
  );
};
