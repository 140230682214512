import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Button } from '../../stories/Button';
import { useStore } from '../../store';

export const GlobalDialog = () => {
  const { clearDialog, dialog } = useStore();

  const handleClose = () => {
    dialog?.onCancel();
    clearDialog();
  };
  const handleConfirm = () => {
    dialog?.onConfirm();
    clearDialog();
  };

  return dialog ? (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {dialog.title ?? 'Confirm'}
      </DialogTitle>
      <DialogContent>{dialog.content}</DialogContent>
      <DialogActions>
        <Button
          label={dialog.cancelButtonLabel ?? 'Cancel'}
          onClick={handleClose}
          variant="outlined"
        />
        <Button
          autoFocus
          label={dialog.confirmButtonLabel ?? 'Yes'}
          onClick={handleConfirm}
        />
      </DialogActions>
    </Dialog>
  ) : null;
};
