import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

export interface CancelTenderModalProps {
    open: boolean;
    close: () => void;
    confirmCancel: (cancelComment: string) => void;
}

export function CancelTenderModal(props: CancelTenderModalProps) {

    const [cancelComment, setCancelComment] = useState<string>('');

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCancelComment(event.target.value);
    }

    return (
        <Dialog
            open={props.open}
        >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        {"Cancel tender for the selected shipments?"}
                    </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Submitting this actions will place these tenders in a TenderCancelled status. Please add a comment below to be associated with these cancels.
                </DialogContentText>
                <TextField style={{ margin: '5px' }}fullWidth multiline rows={4} onChange={handleCommentChange} value={cancelComment} placeholder='Cancel comment here...' />
                </DialogContent>
                </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => props.close()}>
                    Cancel
                </Button>
                <Button variant='outlined' onClick={() => props.confirmCancel(cancelComment)}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
