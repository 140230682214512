import React from 'react';
import Button from '@mui/material/Button';

export interface TenderShipmentButtonProps {
  title: string;
  onClick: () => void;
  disabled: boolean;
}

export function TenderShipmentButton(props: TenderShipmentButtonProps) {
  return (
    <Button
      qa-data="button-manual-tender"
      variant="contained"
      disabled={props.disabled}
      title={props.title}
      style={{ margin: '5px' }}
      onClick={props.onClick}
    >
      Manual Tender
    </Button>
  );
}
