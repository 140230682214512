import axios, { AxiosError } from 'axios';
import { BGRequestConfig, BGRequestError } from '../interceptors';
import { CancelTender } from '../../models/CancelTender';
import { ManualTender } from '../../models/ManualTender';
import { Tender } from '../../models/tender';
import { config } from '../../utils/config';
import { ResponseHandler } from '../interceptors/response';
import {
  CurrentUser,
  Enterprise,
  EnterpriseAddress,
  EnterpriseAddressBook,
  EnterpriseConfiguration,
  NewRefCarrierContactComment,
  NewRefCarrierTender,
  RefCarrier,
  RefCarrierContactComment,
  RefCarrierContactType,
  RefCarrierModeService,
  RefCarrierModeServiceOption,
  RefCarrierServiceLevel,
  RefCarrierTender,
  RefMode,
} from './models';
import {
  NewRefCarrierContact,
  RefCarrierContact,
} from './models/refCarrierContact.model';

const shipmentApiClient = axios.create();
shipmentApiClient.interceptors.request.use(BGRequestConfig, BGRequestError);
shipmentApiClient.interceptors.response.use(ResponseHandler);

const BASE_URL = config.shipmentApiUrl;

const endpoints = {
  // V1
  enterpriseSearch: `${BASE_URL}v1/enterprise/search`,
  getCurrentUser: `${BASE_URL}v1/user/current`,
  getCarriers: `${BASE_URL}v1/Carrier`,
  refCarrierContact: (refCarrierId: RefCarrier['refCarrierID']) =>
    `${BASE_URL}v1/Carrier/RefCarrier/${refCarrierId}/Contact`,
  refCarrierContactModify: (
    refCarrierContactId: RefCarrierContact['refCarrierContactID']
  ) => `${BASE_URL}v1/Carrier/RefCarrierContact/${refCarrierContactId}`,
  refCarrierContactComment: (
    refCarrierContactId: RefCarrierContact['refCarrierContactID']
  ) => `${BASE_URL}v1/Carrier/RefCarrierContact/${refCarrierContactId}/Comment`,
  refCarrierContactCommentModify: (
    refCarrierContactCommentId: RefCarrierContactComment['commentID']
  ) =>
    `${BASE_URL}v1/Carrier/RefCarrierContact/Comment/${refCarrierContactCommentId}`,
  refCarrierModeService: (refCarrierId: number) =>
    `${BASE_URL}v1/Carrier/RefCarrier/${refCarrierId}/ModeService`,
  refCarrierModeServiceDelete: (refCarrierModeServiceId: number) =>
    `${BASE_URL}v1/Carrier/ModeService/${refCarrierModeServiceId}`,
  refCarrierModeServiceTender: (refCarrierModeServiceId: number) =>
    `${BASE_URL}v1/Carrier/ModeService/${refCarrierModeServiceId}/Tender`,
  refCarrierTender: (refCarrierTenderId: number) =>
    `${BASE_URL}v1/Carrier/Tender/${refCarrierTenderId}`,
  refCarrierContactTypes: () => `${BASE_URL}v1/Carrier/contact-types`,
  // V2
  cancelTenders: `${BASE_URL}v2/ShipmentTender/cancelTender`,
  enterpriseAddressBook: `${BASE_URL}v2/EnterpriseAddressBook`,
  getEnterpriseConfiguration: `${BASE_URL}v2/EnterpriseManagement/GetEnterpriseConfiguration`,
  refCarrierServiceLevel: `${BASE_URL}v2/RefCarrierServiceLevel`,
  refMode: `${BASE_URL}v2/RefMode`,
  manuallyTenderShipments: `${BASE_URL}v2/ShipmentTender/manualTender`,
  releaseOrReTender: `${BASE_URL}v2/ShipmentTender/releaseortender`,
  tenderByStatus: `${BASE_URL}v2/ShipmentTender/shipmentByTenderStatus`,
  updateEnterpriseConfiguration: `${BASE_URL}v2/EnterpriseManagement/UpdateEnterpriseConfiguration`,
} as const;

// TODO: zodify
export const fetchTendersByStatus = (status: string) =>
  shipmentApiClient.get<string, Tender[]>(endpoints.tenderByStatus, {
    params: { tenderStatus: status },
  });

// TODO: zodify
export const releaseOrReTender = (shipmentIDs: number[]) =>
  shipmentApiClient.post<number[]>(endpoints.releaseOrReTender, shipmentIDs);

// TODO: zodify
export const cancelTenders = (canceledTenders: CancelTender) =>
  shipmentApiClient.post<CancelTender, any>(
    endpoints.cancelTenders,
    canceledTenders
  );

// TODO: zodify
export const manuallyTenderShipments = (manualTenders: ManualTender) =>
  shipmentApiClient.post<ManualTender, any>(
    endpoints.manuallyTenderShipments,
    manualTenders
  );

export const getCurrentUser = async (userName: CurrentUser['userName']) => {
  const data = await shipmentApiClient.get<
    CurrentUser['userName'],
    CurrentUser
  >(endpoints.getCurrentUser, {
    headers: { UserName: userName },
  });
  return CurrentUser.parse(data);
};

export const enterpriseSearch = async (searchString: string) => {
  const result = await shipmentApiClient.get<string, Enterprise[]>(
    endpoints.enterpriseSearch,
    { params: { searchString } }
  );
  return Enterprise.array().parse(result);
};

export const getEnterpriseAddressBook = async (
  enterpriseId: Enterprise['enterpriseID']
) => {
  const data = await shipmentApiClient.get<
    Enterprise['enterpriseID'],
    EnterpriseAddressBook
  >(endpoints.enterpriseAddressBook, {
    params: { enterpriseID: enterpriseId },
  });

  return EnterpriseAddressBook.parse(data);
};

export const updateEnterpriseAddress = async (
  enterpriseAddress: EnterpriseAddress
) => {
  const data = await shipmentApiClient.put<
    EnterpriseAddress,
    EnterpriseAddress
  >(endpoints.enterpriseAddressBook, enterpriseAddress);
  return EnterpriseAddress.parse(data);
};

export const getEnterpriseConfiguration = async (
  enterpriseId: Enterprise['enterpriseID']
) => {
  const data = await shipmentApiClient.get<
    Enterprise['enterpriseID'],
    EnterpriseConfiguration
  >(endpoints.getEnterpriseConfiguration, {
    params: { enterpriseID: enterpriseId },
  });
  return EnterpriseConfiguration.parse(data);
};

export const updateEnterpriseConfiguration = async (
  enterpriseConfiguration: EnterpriseConfiguration
) => {
  const data = await shipmentApiClient.put<string, EnterpriseConfiguration>(
    endpoints.updateEnterpriseConfiguration,
    enterpriseConfiguration
  );
  return EnterpriseConfiguration.parse(data);
};

export const getRefCarriers = async () => {
  const data = await shipmentApiClient.get<null, RefCarrier[]>(
    endpoints.getCarriers
  );
  return RefCarrier.array().parse(data);
};

export const getRefCarrierServiceLevels = async () => {
  const data = await shipmentApiClient.get<null, RefCarrierServiceLevel>(
    endpoints.refCarrierServiceLevel
  );
  return RefCarrierServiceLevel.array().parse(data);
};

export const addRefCarrierServiceLevel = async (
  refCarrierServiceLevel: RefCarrierServiceLevel
) => {
  const data = await shipmentApiClient.post<
    RefCarrierServiceLevel,
    RefCarrierServiceLevel
  >(endpoints.refCarrierServiceLevel, refCarrierServiceLevel);
  return RefCarrierServiceLevel.parse(data);
};

export const getRefModes = async () => {
  const data = await shipmentApiClient.get<null, RefMode>(endpoints.refMode);
  return RefMode.array().parse(data);
};

export const getRefCarrierModeServices = async (refCarrierId: number) => {
  const data = await shipmentApiClient.get<number, RefCarrierModeServiceOption>(
    endpoints.refCarrierModeService(refCarrierId)
  );
  return RefCarrierModeServiceOption.array().parse(data);
};

export const addRefCarrierModeService = async (
  refCarrierId: number,
  refCarrierModeServiceMap: RefCarrierModeService
) => {
  const data = await shipmentApiClient.post<
    RefCarrierModeService,
    RefCarrierModeServiceOption
  >(endpoints.refCarrierModeService(refCarrierId), refCarrierModeServiceMap);
  return RefCarrierModeServiceOption.parse(data);
};

export const deleteRefCarrierModeService = async (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
) => {
  await shipmentApiClient.delete<
    RefCarrierModeServiceOption['refCarrierModeServiceID']
  >(endpoints.refCarrierModeServiceDelete(refCarrierModeServiceId));
};

export const getRefCarrierModeServiceTender = async (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
) => {
  try {
    const data = await shipmentApiClient.get<RefCarrierTender>(
      endpoints.refCarrierModeServiceTender(refCarrierModeServiceId)
    );

    return RefCarrierTender.parse(data);
  } catch (error: unknown) {
    if (axios.isAxiosError(error) && error.response?.status === 404)
      return null;
    throw error;
  }
};

export const addRefCarrierModeServiceTender = async (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID'],
  refCarrierTender: NewRefCarrierTender
) => {
  const data = await shipmentApiClient.post<RefCarrierTender>(
    endpoints.refCarrierModeServiceTender(refCarrierModeServiceId),
    refCarrierTender
  );
  return RefCarrierTender.parse(data);
};

export const updateRefCarrierTender = async (
  refCarrierTenderId: RefCarrierTender['refCarrierTenderID'],
  refCarrierTender: RefCarrierTender
) => {
  const data = await shipmentApiClient.put<RefCarrierTender>(
    endpoints.refCarrierTender(refCarrierTenderId),
    refCarrierTender
  );
  return RefCarrierTender.parse(data);
};

export const deleteRefCarrierTender = async (
  refCarrierTenderId: RefCarrierTender['refCarrierTenderID']
) => {
  await shipmentApiClient.delete<RefCarrierTender>(
    endpoints.refCarrierTender(refCarrierTenderId)
  );
};

export const getRefCarrierContacts = async (
  refCarrierId: RefCarrier['refCarrierID']
) => {
  const data = await shipmentApiClient.get<RefCarrierContact>(
    endpoints.refCarrierContact(refCarrierId)
  );
  return RefCarrierContact.array().parse(data);
};

export const addRefCarrierContact = async (
  refCarrierId: RefCarrier['refCarrierID'],
  refCarrierContact: NewRefCarrierContact
) => {
  const data = await shipmentApiClient.post<RefCarrierContact>(
    endpoints.refCarrierContact(refCarrierId),
    refCarrierContact
  );
  return RefCarrierContact.parse(data);
};

export const updateRefCarrierContact = async (
  refCarrierContactId: RefCarrierContact['refCarrierContactID'],
  refCarrierContact: RefCarrierContact
) => {
  const data = await shipmentApiClient.put<RefCarrierContact>(
    endpoints.refCarrierContactModify(refCarrierContactId),
    refCarrierContact
  );
  return RefCarrierContact.parse(data);
};

export const deleteRefCarrierContact = async (
  refCarrierContactId: RefCarrierContact['refCarrierContactID']
) => {
  await shipmentApiClient.delete<RefCarrierContact>(
    endpoints.refCarrierContactModify(refCarrierContactId)
  );
};

export const getRefCarrierContactComments = async (
  refCarrierContactId: RefCarrierContact['refCarrierContactID']
) => {
  const data = await shipmentApiClient.get<RefCarrierContactComment>(
    endpoints.refCarrierContactComment(refCarrierContactId)
  );
  return RefCarrierContactComment.array().parse(data);
};

export const addRefCarrierContactComment = async (
  refCarrierContactId: RefCarrierContact['refCarrierContactID'],
  refCarrierContactComment: NewRefCarrierContactComment
) => {
  const data = await shipmentApiClient.post<RefCarrierContactComment>(
    endpoints.refCarrierContactComment(refCarrierContactId),
    refCarrierContactComment
  );
  return RefCarrierContactComment.parse(data);
};

export const updateRefCarrierContactComment = async (
  refCarrierContactCommentId: RefCarrierContactComment['commentID'],
  refCarrierContactComment: RefCarrierContactComment
) => {
  const data = await shipmentApiClient.put<RefCarrierContactComment>(
    endpoints.refCarrierContactCommentModify(refCarrierContactCommentId),
    refCarrierContactComment
  );
  return RefCarrierContactComment.parse(data);
};

export const deleteRefCarrierContactComment = async (
  refCarrierContactCommentId: RefCarrierContactComment['commentID']
) => {
  await shipmentApiClient.delete<RefCarrierContact>(
    endpoints.refCarrierContactCommentModify(refCarrierContactCommentId)
  );
};

export const getRefCarrierContactTypes = async () => {
  const types = await shipmentApiClient.get<RefCarrierContactType[]>(
    endpoints.refCarrierContactTypes()
  );
  return RefCarrierContactType.array().parse(types);
};
