import { Route, Routes } from 'react-router-dom';
import { PATHS } from './paths';

import { ManualTenderBoard } from '../views/tender/manual-board';
import { SigninCallback } from '../views/auth/signInCallback';
import { PendingTenderBoard } from '../views/tender/pending-board';
import { TenderReviewBoard } from '../views/tender/review-board';
import { TenderRejectedBoard } from '../views/tender/rejected-board';
import { TenderCancelledBoard } from '../views/tender/cancelled-board';
import { TenderExpiredBoard } from '../views/tender/expired-board';
import { RequireAuth } from '../auth/RequireAuth';
import { NotFound } from '../views/NotFound/NotFound';
import { Admin } from '../views';

export function Router() {
  return (
    <Routes>
      <Route path={PATHS.SIGN_IN_CALLBACK} element={<SigninCallback />} />
      <Route element={<RequireAuth />}>
        <Route path={PATHS.HOME} element={<PendingTenderBoard />} />
        <Route path={PATHS.TENDER_MANUAL} element={<ManualTenderBoard />} />
        <Route path={PATHS.TENDER_REVIEW} element={<TenderReviewBoard />} />
        <Route path={PATHS.TENDER_REJECTED} element={<TenderRejectedBoard />} />
        <Route path={PATHS.TENDER_CANCELLED} element={<TenderCancelledBoard />} />
        <Route path={PATHS.TENDER_EXPIRED} element={<TenderExpiredBoard />} />
        <Route path={PATHS.ADMIN} element={<Admin />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}