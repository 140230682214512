import { StateCreator } from 'zustand';

export type DialogSlice = {
  dialog: {
    title?: string;
    content: React.ReactElement;
    onCancel: () => void;
    onConfirm: () => void;
    cancelButtonLabel?: string;
    confirmButtonLabel?: string;
  } | null;
  setDialog: (dialog: DialogSlice['dialog']) => void;
  clearDialog: () => void;
};

export const createDialogSlice: StateCreator<DialogSlice, [], [], DialogSlice> =
  (set) => ({
    dialog: null,
    setDialog: (newDialog) => set(() => ({ dialog: newDialog })),
    clearDialog: () => set(() => ({ dialog: null })),
  });
