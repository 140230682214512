import { Box, Card, CardContent, CardHeader, Typography } from '@mui/material';
import { useState } from 'react';
import { EnterpriseSelectField } from '../../../components/fields';
import { Enterprise } from '../../../services/shipmentApi';
import { EnterpriseConfiguration } from './EnterpriseConfiguration';

export const BypassConfig = () => {
  const [selectedEnterprise, setEnterprise] = useState<Enterprise | null>(null);
  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Enterprise Configuration
      </Typography>
      <Card sx={{ width: 'fit-content', minWidth: 400 }}>
        <CardHeader title="Enterprise Tender" />
        <Box sx={{ m: 1, maxWidth: 500 }}>
          <EnterpriseSelectField
            helperText="Select an enterprise to configure"
            onChange={(e, ent) => setEnterprise(ent)}
          />
        </Box>
        {selectedEnterprise && (
          <CardContent>
            <EnterpriseConfiguration enterprise={selectedEnterprise} />
          </CardContent>
        )}
      </Card>
    </>
  );
};
