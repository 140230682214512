import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Page } from '../../components/Page';
import { useAuth } from '../../auth';
import { getPostLoginUri } from '../../auth/utilities';

export const SigninCallback = () => <Page render={() => <Content />} />;

const Content = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  React.useEffect(() => {
    (async () => {
      try {
        auth.signinCallback();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [auth]);

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      navigate(getPostLoginUri());
    }
  }, [auth.isAuthenticated, navigate]);

  return <h1>Signing in...</h1>;
};
