import { useMemo } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { ListItemButton } from '@mui/material';
import { Typography } from '@mui/material';

// TODO: Move to helper types somewhere...
type StringValueKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

function renderRow({ index, style, data: itemData }: ListChildComponentProps) {
  const { onItemClick, data, displayKey, selectedIndex } = itemData;
  const text = displayKey ? data[index][displayKey] : data[index];
  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton
        selected={index === selectedIndex}
        onClick={() => {
          onItemClick && onItemClick(data[index], index, data);
        }}
      >
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

type VirtualizedListBaseProps = {
  height?: number;
  itemSize?: number;
  onItemClick?: (
    item: ListChildComponentProps['data'][number],
    index: ListChildComponentProps['index'],
    array: ListChildComponentProps['data']
  ) => void;
  overscanCount?: number;
  selectedIndex?: number | null;
  /**
   * Width applied to Text Field and Virtualized List.
   *
   * When width is a number less than 1, it will be interpreted as a percentage.
   * Numbers <= 0 will be interpreted as 100%.
   *
   * When width is a number greater than 1, it will be interpreted as explict px.
   *
   * When width is a string - it will be passed to css directly.
   */
  width?: number | string;
};

type VirtualizedStringListProps = VirtualizedListBaseProps & {
  data: string[];
  displayKey?: never;
  notFoundMessage?: string
};

type VirtualizedObjectListProps<
  TData extends Record<string | number | symbol, unknown>[]
> = VirtualizedListBaseProps & {
  data: TData;
  displayKey: StringValueKeys<TData[number]>;
  notFoundMessage?: string;
};

export type VirtualizedListProps<TData = void> = TData extends Record<
  string | number | symbol,
  unknown
>[]
  ? VirtualizedObjectListProps<TData>
  : VirtualizedStringListProps;

export const VirtualizedList = <TData,>({
  data,
  displayKey,
  height = 400,
  itemSize = 46,
  onItemClick,
  overscanCount = 5,
  selectedIndex,
  width = 1,
  notFoundMessage = "No data found."
}: VirtualizedListProps<TData>) => {
  const listWidth = useMemo(() => {
    // Adapter for @MUI styling convention support
    let calcWidth = width;
    if (typeof width === 'number' && width <= 1) calcWidth = `${width * 100}%`;
    return calcWidth;
  }, [width]);

  return (
    <>
      {data.length > 0 ? (
        <>
          <FixedSizeList
            height={height}
            itemCount={data.length}
            itemData={{ onItemClick, data, displayKey, selectedIndex }}
            itemSize={itemSize}
            overscanCount={overscanCount}
            width={listWidth}
          >
            {renderRow}
          </FixedSizeList>
        </>
      ) : (
        <>
          <Typography
            width={400}
            height={200}
            padding={'15px'}
            marginBottom={0}
            variant="h6"
            gutterBottom>
            {notFoundMessage}
          </Typography>
        </>
      )}
    </>
  );
};
