import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '../../../stories/LoadingButton';
import {
  Enterprise,
  useEnterpriseConfigurationMutation,
  useGetEnterpriseConfiguration,
} from '../../../services/shipmentApi';
import { EnterpriseAddressBook } from './EnterpriseAddressBook';
import { useStore } from '../../../store';
import { SkeletonLoader } from '../../../stories/SkeletonLoader';

type EnterpriseConfigurationProps = {
  enterprise: Enterprise;
};
export const EnterpriseConfiguration = ({
  enterprise,
}: EnterpriseConfigurationProps) => {
  const { data: enterpriseConfig, isLoading } = useGetEnterpriseConfiguration(
    enterprise.enterpriseID
  );
  const enterpriseConfigMutation = useEnterpriseConfigurationMutation();
  const [bypassTender, setBypassTender] = useState(
    enterpriseConfig?.bypassTender ?? false
  );
  const [needsTenderReview, setNeedsTenderReview] = useState(
    enterpriseConfig?.needsTenderReview ?? false
  );
  const setSnack = useStore((state) => state.setSnackbar);

  useEffect(() => {
    if (enterpriseConfig) {
      const { bypassTender, needsTenderReview } = enterpriseConfig;
      setBypassTender(bypassTender);
      setNeedsTenderReview(needsTenderReview);
    }
  }, [enterpriseConfig]);

  const handleUpdateClick = async () => {
    if (!enterpriseConfig) return;
    try {
      await enterpriseConfigMutation.mutateAsync({
        ...enterpriseConfig,
        bypassTender,
        needsTenderReview,
      });
      setSnack({
        children: 'Enterprise configuration successfully saved',
        severity: 'success',
      });
    } catch (e) {
      console.error(e); // TODO: Logging
      setSnack({
        children: `There was an error attempting to save configuration for ${enterprise.name}`,
        severity: 'error',
      });
    }
  };

  if (!enterprise) return null;

  return isLoading ? (
    <>
      <Box sx={{ mb: 4 }}>
        <SkeletonLoader variant="text" width={250} height={40} />
        <SkeletonLoader variant="text" width={250} height={40} />
        <SkeletonLoader variant="rounded" width={250} height={40} />
      </Box>

      <SkeletonLoader variant="text" width={300} height={40} />
      <SkeletonLoader variant="rounded" height={300} />
    </>
  ) : (
    <>
      <Typography variant="h5">Global Enterprise Config</Typography>

      <FormGroup sx={{ alignItems: 'flex-start' }}>
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setBypassTender(e.target.checked);
              }}
              checked={bypassTender}
            />
          }
          label="Bypass Tender"
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={(e) => {
                setNeedsTenderReview(e.target.checked);
              }}
              checked={needsTenderReview}
            />
          }
          label="Requires Manual Tender Review"
        />
      </FormGroup>

      <LoadingButton
        isLoading={enterpriseConfigMutation.isLoading}
        label="Save Enterprise Config"
        onClick={handleUpdateClick}
        sx={{ mb: 4 }}
      />
      {!bypassTender && <EnterpriseAddressBook enterprise={enterprise} />}
    </>
  );
};
