declare global {
    interface Window { MyConfig: any }
}

interface Config {
    identityServerUrl: string;
    shipmentApiUrl: string;
    massEditApiUrl: string;
    refreshDuration: number;
    backofficeUrl: string;
}

export const config = window.MyConfig as Config;

