import { Box } from '@mui/material';
import { GridColumnOrderChangeParams, GridFilterModel, GridRowGroupingModel, GridRowSelectionModel } from '@mui/x-data-grid-premium';
import { DataGridPremium, GridColDef } from '@mui/x-data-grid-premium';
import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { updateGroupedRowSelection } from './Actions/GroupedRowsSelection';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      boxShadow: '2px 2px 10px 3px rgba(0,46,109,0.15)',
      overflow: 'hidden',
      '& *': {
        outline: 'none !important'
      },
      '& .MuiDataGrid-footerContainer': {
        background: 'linear-gradient(0deg, rgba(0,0,0,35%) 0%, rgba(255,255,255,100%) 100%)',
        border: 0,
      },
      '& .MuiDataGrid-columnHeaderCheckbox': {
        backgroundColor: '#1976d2',
        '& .MuiCheckbox-root.Mui-checked': {
          color: 'black'
        }
      },
    },
    headers: {
      background: '#1976d2',
      color: 'white',
      textShadow: '1px 2px rgb(0 0 0 / 15%)',
      '& input[type="checkbox"]:checked': {
        display: 'block !important'
      },
    },
    perspective: {
      perspective: '1000px',
    },
    row: {
      transition: 'transform ease-in-out 350ms',
      transformOrigin: 'center center',
      transform: 'rotateX(0deg)',
      '&.Mui-selected': {
        transform: 'rotateX(360deg)',
      },
      '&:hover': {
        background: 'linear-gradient(0deg, rgba(0,154,221,0.12) 0%, rgba(255,255,255,0) 33%)',
      },
    },
    cell: {
      '&:focus': {
        outline: 'none',
      },
      '&:focus-within': {
        outline: 'none !important',
      },
      '& input:focus': {
        outline: 'none',
      },
    },
  };
});

export interface TenderDataGridProps {
  tenders: any[];
  filterStorageObject?: string;
  groupingStorageObject?: string;
  setSelectedShipments: (shipmentIDs: number[]) => void;
  onColumnIndexChange?: (columnPropertyName: string, targetIndex: number) => void;
}

export interface CustomTenderDataGridProps extends TenderDataGridProps {
  columns: GridColDef[];
  filterModel?: GridFilterModel;
  rowGroupingModel?: GridRowGroupingModel;
  onFilterModelChange?: (model: GridFilterModel) => void;
  onRowGroupingModelChange?: (model: GridRowGroupingModel) => void;
}

export default function CustomTenderDataGrid({
  tenders,
  columns,
  setSelectedShipments,
  filterModel,
  onFilterModelChange,
  rowGroupingModel,
  onRowGroupingModelChange,
  onColumnIndexChange,
}: CustomTenderDataGridProps) {

  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 50, // default page size
    page: 0,     // default page
  });

  const [pageSize, setPageSize] = useState<number>(50);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>();

  function handleColumnOrderChange(params: GridColumnOrderChangeParams) {
    if (onColumnIndexChange) {
      onColumnIndexChange(params.column.field, params.targetIndex);
    }
  }

  function extractSelectedShipments(selectionModel: GridRowSelectionModel): number[] {
    console.log(selectionModel);

    const selectedShipmentIds = selectionModel
      .filter(id => !isNaN(parseInt(id.toString())))
      .map(id => parseInt(id.toString()));

    console.log(selectedShipmentIds);

    return selectedShipmentIds;
  }

  const { classes } = useStyles();

  // Add a property name to the columns array
  const modifiedColumns = columns.map(column => ({
    ...column,
    headerClassName: classes.headers,
  }));

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGridPremium
        classes={{
          root: classes.root,
          columnHeaders: classes.headers,
          row: classes.row,
          cell: classes.cell,
          virtualScrollerRenderZone: classes.perspective,
        }}
        getRowId={(row) => row.shipmentID}
        filterModel={filterModel}
        onFilterModelChange={onFilterModelChange}
        rows={tenders}
        columns={modifiedColumns} // Use the modified columns array
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
        pageSizeOptions={[50, 100]}
        pagination={true}
        onRowSelectionModelChange={(selectionModel) => {
          const finalSelectionModel = updateGroupedRowSelection(selectionModel, selectedRows, rowGroupingModel, tenders);
          setSelectedRows(finalSelectionModel);
          setSelectedShipments(extractSelectedShipments(finalSelectionModel));
        }}
        rowSelectionModel={selectedRows}
        onColumnOrderChange={handleColumnOrderChange}
        onRowGroupingModelChange={onRowGroupingModelChange}
        rowGroupingModel={rowGroupingModel}
        checkboxSelection
      />
    </Box>
  );
}