import { GridColDef } from "@mui/x-data-grid-premium";
import { GridActionMenu } from '../../components/DataGrids/Actions/GridActions';

  export const DefaultColumns: GridColDef[] = [
    {
        field: 'primaryReference',
        headerName: 'Primary Reference',
        flex: 1
    },
    {
        field: 'shipmentStatus',
        headerName: 'Shipment Status',
        flex: 1
    },
    {
        field: 'carrierName',
        headerName: 'Carrier Name',
        flex: 1
    },
    {
        field: 'scac',
        headerName: 'SCAC',
        flex: 1
    },
    {
        field: 'actions',
        headerName: 'Actions',
        sortable: false,
        flex: 1,
        headerClassName: 'table-header',
        headerAlign: 'center',
        align: 'center',
        // eslint-disable-next-line react/display-name
        renderCell: (params) => {
            return (
                <>
                    <GridActionMenu shipmentId={params.row.shipmentID} />
                </>
            );
        },
    },
];