import React from 'react';
import { AutocompleteProps as MUIAutocompleteProps } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export type ListboxProps = React.HTMLAttributes<HTMLUListElement> & {
  // It's unfortunate that @mui typings for ListboxComponent and ListboxProps don't allow this to be required...
  getOptionLabel?: MUIAutocompleteProps<
    any,
    false,
    false,
    false
  >['getOptionLabel'];
};

const LISTBOX_PADDING = 8; // px

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps) {
  const {
    data: {
      itemData,
      customProps: { getOptionLabel },
    },
    index,
    style,
  } = props;
  const dataSet = itemData[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet.hasOwnProperty('group')) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const [defaultProps, option, { selected, inputValue }] = dataSet;

  // Search Match Highlighting
  const optionLabel = getOptionLabel ? getOptionLabel(option) : option.label;
  const matches = match(optionLabel, inputValue, {
    insideWords: true,
  });
  const parts = parse(optionLabel, matches);

  return (
    <Typography
      component="li"
      key={defaultProps.id}
      {...defaultProps}
      noWrap
      style={inlineStyle}
    >
      <div>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
        />
        {parts.map((part: any, index: number) => (
          <span
            key={index}
            style={{
              fontWeight: part.highlight ? 700 : 400,
              margin: part.highlight ? '0 1' : 0,
            }}
          >
            {`  ${part.text}  `}
          </span>
        ))}
      </div>
    </Typography>
  );
}

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
export const Listbox = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement> & Pick<ListboxProps, 'getOptionLabel'>
>(function ListboxComponent(props, ref) {
  const { children, getOptionLabel, ...other } = props;
  const itemData: React.ReactElement[] = [];
  (children as React.ReactElement[]).forEach(
    (item: React.ReactElement & { children?: React.ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (child.hasOwnProperty('group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={{ itemData, customProps: { getOptionLabel } }}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index: any) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});
