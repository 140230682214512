import { User } from 'oidc-client';
import { createContext, useContext } from 'react';
import { BlueShipUser } from './AuthProvider';

// TODO: Convert Auth from Context to Zustand store
//       This will remove the need for localstorage usage.
//       Plus there's no real reason to be using Context for this...

type AuthContextType = {
  isAuthenticated: boolean;
  isBGEmployee: boolean;
  isValidUserType: boolean;
  user: BlueShipUser | null;
  userHasOperation: (operation: string) => boolean;
  actions: {
    login: () => void;
    logout: () => void;
    getUser: () => Promise<User | null>;
  };
  signinCallback: () => void;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

export const useAuth = () => useContext(AuthContext);
