import { z } from 'zod';

export const RefCarrierModeService = z.object({
  refModeID: z.number(),
  refCarrierServiceLevelID: z.number(),
});

export type RefCarrierModeService = z.infer<typeof RefCarrierModeService>;

export const RefCarrierModeServiceOption = z.object({
  refCarrierModeServiceID: z.number(),
  modeService: z.string(),
});

export type RefCarrierModeServiceOption = z.infer<
  typeof RefCarrierModeServiceOption
>;
