import { Enterprise, enterpriseSearch } from '../../../services/shipmentApi';
import { AsyncTypeAhead } from '../../../stories/AsyncTypeAhead';

type EnterpriseSelectFieldProps = {
    errorMessage?: string;
    helperText?: string;
    label?: string;
    onChange: Parameters<typeof AsyncTypeAhead<Enterprise>>[0]['onChange']
}

export const EnterpriseSelectField = ({
  errorMessage,
  helperText,
  label,
  onChange,
}: EnterpriseSelectFieldProps) => {
  return (
    <AsyncTypeAhead<Enterprise>
      errorMessage={errorMessage}
      getOptionLabel={(option) => {
        if (!option) return '';
        return `${option.accountNumber}: ${option.name}`;
      }}
      helperText={helperText}
      isOptionEqualToValue={(option, value) => {
        // Known @MUI issue: Waring: The value provided to Autocomplete is invalid. None of the options match.
        // @see: https://github.com/mui/material-ui/issues/29727
        return value.enterpriseID === option.enterpriseID;
      }}
      label={label ?? "Enterprise"}
      onChange={onChange}
      searchEndpoint={enterpriseSearch}
    />
  )
};
