import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import { ZodError } from 'zod';
import { useAuth } from '../../../../auth';
import {
  NewRefCarrierContactComment,
  RefCarrierContact,
  RefCarrierContactComment,
  useAddRefCarrierContactCommentMutation,
  useDeleteRefCarrierContactCommentMutation,
  useGetRefCarrierContactComments,
  useUpdateRefCarrierContactCommentMutation,
} from '../../../../services/shipmentApi';
import { useStore } from '../../../../store';
import { Button } from '../../../../stories/Button';
import { LoadingButton } from '../../../../stories/LoadingButton';
import { SkeletonLoader } from '../../../../stories/SkeletonLoader';

const initialNewComment: NewRefCarrierContactComment = { value: '' } as const;

type CarrierContactCommentsProps = {
  refCarrierContactId: RefCarrierContact['refCarrierContactID'];
};

export const CarrierContactComments = (props: CarrierContactCommentsProps) => (
  <CarrierContactCommentsComponent {...props} key={props.refCarrierContactId} />
);

const CarrierContactCommentsComponent = ({
  refCarrierContactId,
}: CarrierContactCommentsProps) => {
  const auth = useAuth();
  const setSnack = useStore((state) => state.setSnackbar);
  const [isCommentsEnabled, setIsCommentsEnabled] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [newComment, setNewComment] = useState(initialNewComment);
  const editingCommentRef = useRef<TextFieldProps>();
  const [parseErrors, setParseErrors] =
    useState<
      ReturnType<ZodError<RefCarrierContactComment>['flatten']>['fieldErrors']
    >();
  const [editingCommentId, setEditingCommentId] =
    useState<RefCarrierContactComment['commentID']>();
  const { data: comments = [], isLoading } = useGetRefCarrierContactComments(
    refCarrierContactId,
    {
      enabled: isCommentsEnabled,
    }
  );
  const addCommentMutation = useAddRefCarrierContactCommentMutation(
    refCarrierContactId,
    auth.user?.userName || 'TempUser'
  );
  const updateCommentMutation =
    useUpdateRefCarrierContactCommentMutation(refCarrierContactId);
  const deleteCommentMutation =
    useDeleteRefCarrierContactCommentMutation(refCarrierContactId);

  const handleClick = () => {
    if (!isCommentsEnabled) setIsCommentsEnabled(true);
    setIsCommentsOpen(!isCommentsOpen);
  };

  const isDisabled =
    addCommentMutation.isLoading ||
    updateCommentMutation.isLoading ||
    deleteCommentMutation.isLoading;

  const closeEditingComment = () => {
    setEditingCommentId(undefined);
    editingCommentRef.current = undefined;
  };

  return (
    <Accordion
      disableGutters
      elevation={0}
      expanded={isCommentsOpen}
      onChange={handleClick}
      square
      sx={{
        mt: 2,
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} sx={{ p: 0 }}>
        <Typography variant="h6">Comments</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        {isLoading && (
          <>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
            >
              <SkeletonLoader variant="circular">
                <Avatar />
              </SkeletonLoader>
              <Stack width="80%" direction="column">
                <SkeletonLoader width="80%" />
                <SkeletonLoader width="80%" />
              </Stack>
            </Stack>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
            >
              <SkeletonLoader
                width="70%"
                height={100}
                sx={{ transform: 'none' }}
              />
              <SkeletonLoader
                width={100}
                height={40}
                sx={{ transform: 'none' }}
              />
            </Stack>
          </>
        )}
        {!isLoading && comments.length === 0 ? (
          <Typography variant="body1" textAlign={'center'}>
            No Comments
          </Typography>
        ) : (
          <List sx={{ pt: 0, mt: '-10px' }}>
            {comments.map((c, idx) => {
              const isNotMyComment = c.userCreated !== auth.user?.userName;
              const isEditingComment = c.commentID === editingCommentId;
              return (
                <Fragment key={`${c.userCreated}-${idx}`}>
                  <ListItem
                    alignItems="flex-start"
                    secondaryAction={
                      isEditingComment ? (
                        <>
                          <LoadingButton
                            isLoading={updateCommentMutation.isLoading}
                            disabled={isDisabled}
                            label="save"
                            onClick={async () => {
                              const data = {
                                ...c,
                                value: (
                                  editingCommentRef.current?.value as string
                                ).trim(),
                              };
                              const parsed =
                                RefCarrierContactComment.safeParse(data);
                              if (parsed.success) {
                                setParseErrors(undefined);
                                try {
                                  await updateCommentMutation.mutateAsync(
                                    parsed.data
                                  );
                                  closeEditingComment();
                                } catch (e) {
                                  console.error(e); // TODO: Logging
                                  setSnack({
                                    children:
                                      'There was an error attempting to update Comment',
                                    severity: 'error',
                                  });
                                }
                              } else {
                                setParseErrors(
                                  parsed.error.flatten().fieldErrors
                                );
                              }
                            }}
                            size="small"
                            sx={{ p: 0 }}
                            variant="text"
                          />
                          <Button
                            disabled={isDisabled}
                            label="cancel"
                            onClick={closeEditingComment}
                            size="small"
                            sx={{ p: 0 }}
                            variant="text"
                          />
                        </>
                      ) : (
                        <>
                          <Button
                            disabled={isDisabled || isNotMyComment}
                            label="edit"
                            onClick={() => {
                              setEditingCommentId(c.commentID);
                              setParseErrors(undefined);
                            }}
                            size="small"
                            sx={{ p: 0, opacity: '80%' }}
                            variant="text"
                          />
                          <LoadingButton
                            color="error"
                            disabled={isDisabled || isNotMyComment}
                            isLoading={
                              deleteCommentMutation.isLoading &&
                              c.commentID === deleteCommentMutation.variables
                            }
                            label="delete"
                            onClick={async () => {
                              try {
                                await deleteCommentMutation.mutateAsync(
                                  c.commentID
                                );
                              } catch (e) {
                                console.error(e); // TODO: Logging
                                setSnack({
                                  children:
                                    'There was an error attempting to delete Comment',
                                  severity: 'error',
                                });
                              }
                            }}
                            size="small"
                            sx={{ p: 0, opacity: '80%' }}
                            variant="text"
                          />
                        </>
                      )
                    }
                    sx={{
                      pr: 0,
                      '& .MuiListItemSecondaryAction-root': {
                        top: '14px',
                        right: 0,
                        transform: 'none',
                      },
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar alt={c.userCreated} />
                    </ListItemAvatar>
                    <ListItemText
                      primary={c.userCreated}
                      secondary={
                        isEditingComment ? (
                          <TextField
                            defaultValue={c.value}
                            disabled={isDisabled}
                            error={Boolean(parseErrors)}
                            helperText={parseErrors?.value?.join('; ')}
                            inputRef={editingCommentRef}
                            multiline
                            rows={4}
                            sx={{ width: '100%' }}
                          />
                        ) : (
                          c.value
                        )
                      }
                    />
                  </ListItem>
                  <Divider />
                </Fragment>
              );
            })}
          </List>
        )}
        {!isLoading && (
          <>
            <Stack
              alignItems="flex-start"
              direction="row"
              justifyContent="space-between"
              sx={{ mt: 2 }}
            >
              <TextField
                disabled={isDisabled}
                label="Add a comment"
                multiline
                onChange={(e) => setNewComment({ value: e.target.value })}
                rows={4}
                sx={{ width: '75%' }}
                value={newComment.value}
              />
              <LoadingButton
                disabled={newComment.value.length < 1 || isDisabled}
                isLoading={addCommentMutation.isLoading}
                label="Add"
                onClick={async () => {
                  try {
                    await addCommentMutation.mutateAsync(newComment);
                    setNewComment(initialNewComment);
                  } catch (e) {
                    console.error(e); // TODO: Logging
                    setSnack({
                      children: 'There was an error attempting to add Comment',
                      severity: 'error',
                    });
                  }
                }}
              />
            </Stack>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
