import { Alert, Slide, Snackbar } from "@mui/material";
import { useStore } from "../../store";

export const GlobalSnackbar = () => {
  const { clearSnackbar, snackbar } = useStore();

  return Boolean(snackbar) ? (
    <Snackbar
      open
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      onClose={clearSnackbar}
      autoHideDuration={6000}
      TransitionComponent={(props) => <Slide {...props} direction="up" />}
    >
      <Alert variant="filled" {...snackbar} onClose={clearSnackbar} />
    </Snackbar>
  ) : null;
};
