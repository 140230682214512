import { Add, Edit, Remove } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import {
  RefCarrier,
  useGetRefCarrierContactTypes,
  useGetRefCarrierContacts,
} from '../../../../services/shipmentApi';
import { Button } from '../../../../stories/Button';
import { VirtualizedList } from '../../../../stories/List';
import { SkeletonLoader } from '../../../../stories/SkeletonLoader';
import { CarrierContactDetails } from './CarrierContactDetails';
import { CarrierContactForm } from './CarrierContactForm';
import { useStore } from '../../../../store';
import { LoadingButton } from '../../../../stories/LoadingButton';
import { useDeleteRefCarrierContactMutation } from '../../../../services/shipmentApi';
import { CarrierContactComments } from './CarrierContactComments';

type CarrierContactsListProps = {
  refCarrierId: RefCarrier['refCarrierID'];
};

export const CarrierContactsList = ({
  refCarrierId,
}: CarrierContactsListProps) => {
  const { data: carrierContactTypes, isLoading: isLoadingCarrierContactTypes } =
    useGetRefCarrierContactTypes();
  const { data: refCarrierContacts = [], isLoading: isLoadingCarrierContacts } =
    useGetRefCarrierContacts(refCarrierId);
  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [isAddingContact, setIsAddingContact] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const deleteRefCarrierContactMutation =
    useDeleteRefCarrierContactMutation(refCarrierId);
  const setSnack = useStore((state) => state.setSnackbar);
  const setDialog = useStore((state) => state.setDialog);

  const selectedContact =
    typeof selectedIndex === 'number'
      ? refCarrierContacts[selectedIndex]
      : undefined;
  const contactList = refCarrierContacts?.map(
    (rcc) => `${rcc.contact.name} - ${rcc.contactType.type}`
  );
  const isUpdating = deleteRefCarrierContactMutation.isLoading;

  const handleDeleteCarrierContact = async () => {
    try {
      if (!selectedContact) throw Error('No contact selected to delete.');
      await deleteRefCarrierContactMutation.mutateAsync(
        selectedContact.refCarrierContactID
      );
      setSnack({
        children: 'Contact successfully deleted',
        severity: 'success',
      });
      setSelectedIndex(undefined);
    } catch (e) {
      console.error(e); // TODO: Logging
      setSnack({
        children: `There was an error attempting to delete selected Contact`,
        severity: 'error',
      });
    }
  };

  const confirmDelete = () => {
    setDialog({
      content: (
        <>
          Do you really want to delete{' '}
          <strong>{selectedContact!.contact.name}</strong>?
        </>
      ),
      onCancel: () => {
        /* NOOP */
      },
      onConfirm: () => handleDeleteCarrierContact(),
    });
  };

  const isLoading =
    isLoadingCarrierContactTypes ||
    isLoadingCarrierContacts ||
    !carrierContactTypes;

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonLoader variant="text" width={200} height={40} />
          <SkeletonLoader variant="rectangular" height={200} />
          <SkeletonLoader variant="text" height={40} />
        </>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Contacts
          </Typography>
          <Box sx={{ width: 400, bgcolor: 'hsl(0, 0%, 97%)' }}>
            <VirtualizedList
              data={contactList!}
              onItemClick={(_, idx) => {
                setSelectedIndex(idx);
              }}
              selectedIndex={selectedIndex}
              width={400}
              height={200}
              notFoundMessage="No associated contacts for selected carrier."
            />
          </Box>
          {!isAddingContact && !isEditMode && (
            <Button
              disabled={isUpdating}
              label="Add"
              onClick={() => {
                setIsAddingContact(!isAddingContact);
              }}
              startIcon={<Add />}
              sx={{ mt: 1 }}
              variant="text"
            />
          )}
          {(isAddingContact || isEditMode) && (
            <CarrierContactForm
              carrierContactTypes={carrierContactTypes}
              refCarrierId={refCarrierId}
              refCarrierContact={isAddingContact ? undefined : selectedContact}
              onSave={() => {
                if (isAddingContact)
                  setSelectedIndex(refCarrierContacts.length);
                setIsEditMode(false);
                setIsAddingContact(false);
              }}
              onCancel={() => {
                setIsEditMode(false);
                setIsAddingContact(false);
              }}
            />
          )}
          {selectedContact && !isAddingContact && !isEditMode && (
            <>
              <CarrierContactDetails refCarrierContact={selectedContact} />
              <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  disabled={isUpdating}
                  label="Edit"
                  onClick={() => setIsEditMode(!isEditMode)}
                  startIcon={<Edit />}
                  sx={{ mt: 1 }}
                  variant="text"
                />
                <LoadingButton
                  color="error"
                  isLoading={isUpdating}
                  label="Delete"
                  onClick={confirmDelete}
                  startIcon={<Remove />}
                  sx={{ mt: 1 }}
                  variant="text"
                />
              </Stack>
              <CarrierContactComments
                refCarrierContactId={selectedContact.refCarrierContactID}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
