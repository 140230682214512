import Button from '@mui/material/Button';
import LayersClearIcon from '@mui/icons-material/LayersClear';

export interface ResetRowGroupsButtonProps {
  title: string;
  onClick: () => void;
}

export function ResetRowGroupsButton(props: ResetRowGroupsButtonProps) {
  return (
    <Button
      variant='contained'
      style={{margin: '5px'}}
      sx={{
        mx: 1,
        pr: 0,
      }}
      title={props.title}
      onClick={props.onClick}
      startIcon={<LayersClearIcon style={{ fontSize: 30 }} />}
    />
  );
}
