import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

export interface ManualTenderModalProps {
    open: boolean;
    close: () => void;
    confirmManualTender: (comment: string) => void;
}

export function ManualTenderModal(props: ManualTenderModalProps) {

    const [manualTenderComment, setManualTenderComment] = useState<string>('');

    const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setManualTenderComment(event.target.value);
    }

    return (
        <Dialog
            open={props.open}
        >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        {"Manually tender the selected shipments?"}
                    </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Submitting this actions will place these shipments in a Booked status. Please add a comment below to be associated with these tenders.
                </DialogContentText>
                <TextField style={{ margin: '5px' }}fullWidth multiline rows={4} onChange={handleCommentChange} value={manualTenderComment} placeholder='Add comments here...' />
                </DialogContent>
                </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => props.close()}>
                    Cancel
                </Button>
                <Button variant='outlined' onClick={() => props.confirmManualTender(manualTenderComment)}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
