import React, { useState } from 'react';
import { Log, UserManager, WebStorageStateStore, User } from 'oidc-client';
import { AuthContext } from './AuthContext';
import { USER_ROLES, USER_TYPES } from './constants';
import { config } from '../utils/config';
import { getCurrentUser } from '../services/shipmentApi';

export type BlueShipUser = {
  userName: string;
  userID: number;
  userType: string;
  enterpriseID: number;
  name: string;
  email: string;
  operations: string[];
};

const {
  location: { port, protocol, hostname },
} = window;
const baseUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

const oidcConfig = {
  authority: config.identityServerUrl,
  client_id: 'tender-ui', 
  redirect_uri: `${baseUrl}/signin-callback`,
  silent_redirect_uri: `${baseUrl}/silent-renew.html`,
  post_logout_redirect_uri: baseUrl,
  response_type: 'id_token token',
  scope: 'openid profile',
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

Log.logger = console;
Log.level = Log.INFO;

const userManager = new UserManager(oidcConfig);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<BlueShipUser | null>(null);
  React.useEffect(() => {
    userManager.events.addAccessTokenExpired(() => {
      console.log('Logging out due to inactivity...');
      logout();
    });

    (async () => {
      const result = await userManager.getUser();
      if (result) {
        await setBlueshipUser(result);
      }
    })();
  }, []);

  const userHasOperation = (operation: string): boolean => {
    if (!user) return false;
    const operations = user.operations;
    return operations.indexOf(operation) > -1;
  };

  const setBlueshipUser = async (user: User) => {
    const {
      profile: { name },
    } = user;

    try {
      const data = await getCurrentUser(name!);

      if (!data) {
        alert('no user exists in db');
      }

      if (data?.firstName) {
        const userDetails = {
          userName: data.userName,
          userID: data.userID,
          userType: data.userType,
          enterpriseID: data.enterpriseID,
          name: `${data.firstName} ${data.lastName}`,
          email: data.email,
          operations: data.operations ?? [],
        }
        setUser(userDetails);
        // TODO: Really shouldn't be using localstorage for auth details...
        localStorage.setItem('BGUser', JSON.stringify(userDetails));
      }
    } catch (e: any) {
      console.log('Unable to fetch user details');
    }
  };

  const login = () => {
      userManager.signinRedirect();
  };

  const logout = () => {
    userManager.signoutRedirect();
  };

  const getUser = async () => {
    return await userManager.getUser();
  }

  const signinCallback = async () => {
    const result = await userManager.signinRedirectCallback();
    if (!result) {
      console.log(
        'AuthProvider.tsx: sigininCallback - completeAuthentication failed'
      );
    } else {
      setBlueshipUser(result);
    }
  };

  const isValidUserType =
    ((user?.userType === USER_TYPES.AGENT ||
      user?.userType === USER_TYPES.BG_EMPLOYEE ||
      user?.userType === USER_TYPES.GROUP));

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        isBGEmployee: user?.userType === USER_TYPES.BG_EMPLOYEE,
        isValidUserType,
        user,
        userHasOperation,
        actions: { login, logout, getUser },
        signinCallback,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
