import { actionsDisabled } from '../../utils/actionsDisabled';
import { DefaultColumns } from './data';
import {
  fetchTendersByStatus,
  manuallyTenderShipments,
} from '../../services/shipmentApi';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ManualTender } from '../../models/ManualTender';
import { ManualTenderModal } from '../../components/controls/modals/manualTenderModal';
import { removeOrAddColumn, reorderColumn } from '../../utils/columnReorder';
import { StandardBoard } from '../../components/standardBoard';
import { Tender } from '../../models/tender';
import { TenderShipmentButton } from '../../components/controls/buttons/tenderShipmentButton';
import { useAuth } from '../../auth';
import { useCallback, useEffect, useState } from 'react';
import { columnDataFormatter } from '../../utils/columnDataFormatter';

export function ManualTenderBoard() {
  const columnsStorageKey = 'manualBoardColumns';

  const auth = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [tenders, setTenders] = useState<Tender[]>([]);
  const [selectedShipments, setSelectedShipments] = useState<number[]>([]);
  const [isColumnsDialogOpen, setColumnsOpen] = useState(false);
  const [manualTenderModalOpen, setManualTenderModalOpen] = useState(false);
  const [columns, setColumns] = useState<GridColDef[]>(DefaultColumns);

  useEffect(() => {
    if (localStorage.getItem(columnsStorageKey)) {
      let columnObj = localStorage.getItem(columnsStorageKey);
      let obj = columnObj && JSON.parse(columnObj);

      if (obj) {
        let formattedColumns = columnDataFormatter(obj);
        setColumns(formattedColumns);
      }
    }
  }, []);

  const fetchData = useCallback(async () => {
    const data = await fetchTendersByStatus('TenderManual');
    setTenders(data);
    setIsLoading(false);
  }, []);

  const manualTenderShipments = async (comment: string) => {
    setManualTenderModalOpen(false);
    setIsLoading(true);

    let mt: ManualTender = {
      shipmentIDs: selectedShipments,
      comment: comment,
    };

    await manuallyTenderShipments(mt);

    fetchData();
    setIsLoading(false);
  };

  const onColumnAddedRemoved = (column: any) => {
    let updatedColumns = removeOrAddColumn(columns, column, columnsStorageKey);
    setColumns(updatedColumns);
  };

  const onColumnIndexChange = (
    columnPropertyName: string,
    targetIndex: number
  ) => {
    let updatedColumns = reorderColumn(
      columns,
      columnPropertyName,
      targetIndex,
      columnsStorageKey
    );
    setColumns(updatedColumns);
  };

  useEffect(() => {
    fetchData().catch(console.error);
  }, [fetchData]);

  function onColumnsDialogClose() {
    setManualTenderModalOpen(false);
    setColumnsOpen(false);
  }

  return (
    <StandardBoard
      onRefreshButtonClick={fetchData}
      title="Manual Tender Board"
      primaryElement={
        <TenderShipmentButton
          disabled={actionsDisabled(selectedShipments)}
          title="Tender the selected shipments"
          onClick={() => setManualTenderModalOpen(true)}
        />
      }
      gridProps={{
        filterStorageObject: 'manualBoardFilters',
        groupingStorageObject: 'manualBoardGrouping',
        setSelectedShipments: setSelectedShipments,
        tenders: tenders,
        onColumnIndexChange,
      }}
      columns={columns}
      isColumnsDialogOpen={isColumnsDialogOpen}
      onColumnsDialogChanged={onColumnAddedRemoved}
      onColumnsDialogClose={onColumnsDialogClose}
      isBackdropVisible={isLoading}
      onColumnButtonClick={() => setColumnsOpen(true)}
    >
      <ManualTenderModal
        open={manualTenderModalOpen}
        close={onColumnsDialogClose}
        confirmManualTender={manualTenderShipments}
      />
    </StandardBoard>
  );
}
