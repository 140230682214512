import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  addRefCarrierModeService,
  addRefCarrierServiceLevel,
  deleteRefCarrierTender,
  deleteRefCarrierModeService,
  getEnterpriseAddressBook,
  getEnterpriseConfiguration,
  getRefCarrierModeServices,
  getRefCarriers,
  getRefCarrierServiceLevels,
  getRefModes,
  updateEnterpriseAddress,
  updateEnterpriseConfiguration,
  addRefCarrierModeServiceTender,
  getRefCarrierModeServiceTender,
  updateRefCarrierTender,
  getRefCarrierContacts,
  addRefCarrierContact,
  updateRefCarrierContact,
  deleteRefCarrierContact,
  getRefCarrierContactComments,
  addRefCarrierContactComment,
  updateRefCarrierContactComment,
  deleteRefCarrierContactComment,
  getRefCarrierContactTypes,
} from './shipment.api';
import {
  EnterpriseAddress,
  EnterpriseConfiguration,
  NewRefCarrierContactComment,
  NewRefCarrierTender,
  RefCarrier,
  RefCarrierContactComment,
  RefCarrierModeService,
  RefCarrierModeServiceOption,
  RefCarrierServiceLevel,
  RefCarrierTender,
} from './models';
import {
  NewRefCarrierContact,
  RefCarrierContact,
} from './models/refCarrierContact.model';

const QUERY_KEYS = {
  ENTERPRISE_ADDRESS_BOOK: 'EnterpriseAddressBook',
  ENTERPRISE_CONFIG: 'EnterpriseConfiguration',
  REF_CARRIER_CONTACT_COMMENTS: 'RefCarrierContactComments',
  REF_CARRIER_CONTACT_TYPES: 'RefCarrierContactTypes',
  REF_CARRIER_CONTACTS: 'RefCarrierContacts',
  REF_CARRIER_MODE_SERVICE: 'RefCarrierModeService',
  REF_CARRIER_SERVICE_LEVELS: 'RefCarrierServiceLevels',
  REF_CARRIER_TENDER: 'RefCarrierTenders',
  REF_CARRIERS: 'RefCarriers',
  REF_MODES: 'RefModes',
} as const;

export const useGetEnterpriseAddressBook = (
  enterpriseId: Parameters<typeof getEnterpriseAddressBook>[number]
) =>
  useQuery({
    queryKey: [QUERY_KEYS.ENTERPRISE_ADDRESS_BOOK, enterpriseId],
    queryFn: async () => await getEnterpriseAddressBook(enterpriseId),
  });

export const useEnterpriseAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (enterpriseAddress: EnterpriseAddress) =>
      updateEnterpriseAddress(enterpriseAddress),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.ENTERPRISE_ADDRESS_BOOK],
        });
      },
    }
  );
};

export const useGetEnterpriseConfiguration = (
  enterpriseId: Parameters<typeof getEnterpriseConfiguration>[number]
) =>
  useQuery({
    queryKey: [QUERY_KEYS.ENTERPRISE_CONFIG, enterpriseId],
    queryFn: async () => await getEnterpriseConfiguration(enterpriseId),
  });

export const useEnterpriseConfigurationMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (enterpriseConfiguration: EnterpriseConfiguration) =>
      updateEnterpriseConfiguration(enterpriseConfiguration),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.ENTERPRISE_CONFIG],
        });
      },
    }
  );
};

export const useGetRefCarrier = () =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIERS],
    queryFn: async () => await getRefCarriers(),
  });

export const useGetRefCarrierServiceLevels = () =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIER_SERVICE_LEVELS],
    queryFn: async () => await getRefCarrierServiceLevels(),
  });

export const useRefCarrierServiceLevelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierServiceLevel: RefCarrierServiceLevel) =>
      addRefCarrierServiceLevel(refCarrierServiceLevel),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_SERVICE_LEVELS],
        });
      },
    }
  );
};

export const useGetRefModes = () =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_MODES],
    queryFn: async () => await getRefModes(),
  });

export const useGetRefCarrierModeServices = (
  refCarrierId: Parameters<typeof getRefCarrierModeServices>[number]
) =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIER_MODE_SERVICE, refCarrierId],
    queryFn: async () => await getRefCarrierModeServices(refCarrierId),
  });

export const useAddRefCarrierModeServiceMutation = (refCarrierId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierModeService: RefCarrierModeService) =>
      addRefCarrierModeService(refCarrierId, refCarrierModeService),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_MODE_SERVICE, refCarrierId],
        });
      },
    }
  );
};

export const useDeleteRefCarrierModeServiceMutation = (
  refCarrierId: number
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (
      refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
    ) => deleteRefCarrierModeService(refCarrierModeServiceId),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_MODE_SERVICE, refCarrierId],
        });
      },
    }
  );
};

export const useGetRefCarierModeServiceTender = (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
) =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIER_TENDER, refCarrierModeServiceId],
    queryFn: async () =>
      await getRefCarrierModeServiceTender(refCarrierModeServiceId),
    retry: (failureCount, error: any) => error.resopnse.status === 404,
  });

export const useAddRefCarierModeServiceTenderMutation = (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierTender: NewRefCarrierTender) =>
      addRefCarrierModeServiceTender(refCarrierModeServiceId, refCarrierTender),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_TENDER, refCarrierModeServiceId],
        });
      },
    }
  );
};

export const useUpdateRefCarierTenderMutation = (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierTender: RefCarrierTender) =>
      updateRefCarrierTender(
        refCarrierTender.refCarrierTenderID,
        refCarrierTender
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_TENDER, refCarrierModeServiceId],
        });
      },
    }
  );
};

export const useDeleteRefCarierTenderMutation = (
  refCarrierModeServiceId: RefCarrierModeServiceOption['refCarrierModeServiceID']
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierTenderId: RefCarrierTender['refCarrierTenderID']) =>
      deleteRefCarrierTender(refCarrierTenderId),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_TENDER, refCarrierModeServiceId],
        });
      },
    }
  );
};

export const useGetRefCarrierContacts = (
  refCarrierId: RefCarrier['refCarrierID']
) =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIER_CONTACTS, refCarrierId],
    queryFn: async () => await getRefCarrierContacts(refCarrierId),
  });

export const useAddRefCarrierContactMutation = (
  refCarrierId: RefCarrier['refCarrierID']
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierContact: NewRefCarrierContact) =>
      addRefCarrierContact(refCarrierId, refCarrierContact),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_CONTACTS, refCarrierId],
        });
      },
    }
  );
};

export const useUpdateRefCarrierContactMutation = (
  refCarrierId: RefCarrier['refCarrierID']
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierContact: RefCarrierContact) =>
      updateRefCarrierContact(
        refCarrierContact.refCarrierContactID,
        refCarrierContact
      ),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_CONTACTS, refCarrierId],
        });
      },
    }
  );
};

export const useDeleteRefCarrierContactMutation = (
  refCarrierId: RefCarrier['refCarrierID']
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (refCarrierContactId: RefCarrierContact['refCarrierContactID']) =>
      deleteRefCarrierContact(refCarrierContactId),
    {
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.REF_CARRIER_CONTACTS, refCarrierId],
        });
      },
    }
  );
};

export const useGetRefCarrierContactComments = (
  refCarrierContactId: RefCarrierContact['refCarrierContactID'],
  useQueryOptions: Pick<UseQueryOptions, 'enabled'>
) =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIER_CONTACT_COMMENTS, refCarrierContactId],
    queryFn: async () =>
      await getRefCarrierContactComments(refCarrierContactId),
    ...useQueryOptions,
  });

export const useAddRefCarrierContactCommentMutation = (
  refCarrierContactId: RefCarrierContact['refCarrierContactID'],
  userName: string
) => {
  const queryKey = [
    QUERY_KEYS.REF_CARRIER_CONTACT_COMMENTS,
    refCarrierContactId,
  ];
  const queryClient = useQueryClient();
  return useMutation(
    (comment: NewRefCarrierContactComment) =>
      addRefCarrierContactComment(refCarrierContactId, comment),
    {
      onSettled: (newComment, error) => {
        if (!error && newComment) {
          queryClient.setQueryData<RefCarrierContactComment[]>(
            queryKey,
            (old) => [newComment].concat(old || [])
          );
        }
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.REF_CARRIER_CONTACT_COMMENTS,
            refCarrierContactId,
          ],
        });
      },
    }
  );
};

export const useUpdateRefCarrierContactCommentMutation = (
  refCarrierContactId: RefCarrierContact['refCarrierContactID']
) => {
  const queryKey = [
    QUERY_KEYS.REF_CARRIER_CONTACT_COMMENTS,
    refCarrierContactId,
  ];
  const queryClient = useQueryClient();
  return useMutation(
    (comment: RefCarrierContactComment) =>
      updateRefCarrierContactComment(comment.commentID, comment),
    {
      onSettled: (updatedComment, error) => {
        if (!error && updatedComment) {
          queryClient.setQueryData<RefCarrierContactComment[]>(
            queryKey,
            (old) =>
              old?.map((o) =>
                o.commentID === updatedComment.commentID ? updatedComment : o
              )
          );
        }
        queryClient.invalidateQueries({
          queryKey,
        });
      },
    }
  );
};

export const useDeleteRefCarrierContactCommentMutation = (
  refCarrierContactId: RefCarrierContact['refCarrierContactID']
) => {
  const queryKey = [
    QUERY_KEYS.REF_CARRIER_CONTACT_COMMENTS,
    refCarrierContactId,
  ];
  const queryClient = useQueryClient();
  return useMutation(
    (commentId: RefCarrierContactComment['commentID']) =>
      deleteRefCarrierContactComment(commentId),
    {
      onSettled: (_, error, variables) => {
        if (!error) {
          queryClient.setQueryData<RefCarrierContactComment[]>(
            queryKey,
            (old) => old?.filter((o) => o.commentID !== variables)
          );
        }
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.REF_CARRIER_CONTACT_COMMENTS,
            refCarrierContactId,
          ],
        });
      },
    }
  );
};

export const useGetRefCarrierContactTypes = () =>
  useQuery({
    queryKey: [QUERY_KEYS.REF_CARRIER_CONTACT_TYPES],
    queryFn: async () => await getRefCarrierContactTypes(),
  });
