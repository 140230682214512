import React from 'react';
import Button from '@mui/material/Button';

export interface CancelTenderButtonProps {
  title: string;
  onClick: () => void;
  disabled: boolean;
}

export function CancelTenderButton(props: CancelTenderButtonProps) {
  return (
    <Button
      qa-data="button-cancel-tender"
      variant="contained"
      disabled={props.disabled}
      title={props.title}
      style={{ margin: '5px' }}
      onClick={props.onClick}
    >
      Cancel Tender
    </Button>
  );
}
