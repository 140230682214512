import React from 'react';
import {
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridRowParams,
  MuiEvent,
} from '@mui/x-data-grid-premium';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { ZodError } from 'zod';
import { Box } from '@mui/material';
import { Enterprise, EnterpriseAddress } from '../../../services/shipmentApi';
import {
  useGetEnterpriseAddressBook,
  useEnterpriseAddressMutation,
} from '../../../services/shipmentApi/shipment.api.hooks';
import { Board } from '../../../stories/Board';
import { useStore } from '../../../store';

type EnterpriseAddressBookProps = {
  enterprise: Enterprise;
};

export const EnterpriseAddressBook = ({
  enterprise,
}: EnterpriseAddressBookProps) => {
  const { status, data, error } = useGetEnterpriseAddressBook(
    enterprise.enterpriseID
  );
  const enterpriseAddressMutation = useEnterpriseAddressMutation();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const setSnack = useStore((state) => state.setSnackbar);

  if (error instanceof ZodError) {
    console.error(error.message);
    setSnack({
      children: `Uh oh! There was an error fetching data for  ${enterprise.name}`,
      severity: 'error',
    });
  }

  const handleRowEditStart = (
    params: GridRowParams,
    event: MuiEvent<React.SyntheticEvent>
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (
    params,
    event
  ) => {
    event.defaultMuiPrevented = true;
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
  };

  const processRowUpdate = React.useCallback(
    async (newRow: GridRowModel<EnterpriseAddress>) => {
      try {
        const updatedRecord = await enterpriseAddressMutation.mutateAsync(
          newRow
        );
        setSnack({
          children: 'Tender bypass setting successfully saved',
          severity: 'success',
        });
        return updatedRecord;
      } catch (e) {
        console.error(e); // TODO: Logging
        setSnack({
          children: `There was an error attempting to save ${newRow.companyName} - ${newRow.addressLine1}`,
          severity: 'error',
        });
      }
      return newRow;
    },
    [enterpriseAddressMutation, setSnack]
  );

  // @MUI isn't using TypeScript very well for dynamically typing their data grid. Sad.

  const columns: GridColDef[] = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }: { id: GridRowId }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      width: 150,
    },
    {
      field: 'addressLine1',
      headerName: 'Address Line 1',
      width: 150,
    },
    {
      field: 'addressLine2',
      headerName: 'Address Line 2',
    },
    {
      field: 'city',
      headerName: 'City',
    },
    {
      field: 'stateProvince',
      headerName: 'State / Province',
    },
    {
      field: 'postalCode',
      headerName: 'Postal Code',
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
    },
    {
      field: 'locationCode',
      headerName: 'Location Code',
    },
    {
      field: 'isBypassTender',
      headerName: 'Bypass Tender',
      type: 'boolean',
      editable: true,
    },
  ];
  return (
    <>
      {error instanceof Error ? (
        // TODO: Actual Error handling
        <Box>
          Uh oh! There was an error when fetching data for {enterprise.name}
        </Box>
      ) : (
        <Box sx={{ width: '1200px' }}>
          <Board
            title="Enterprise Address Settings"
            isColumnsButtonVisible={false}
            columns={columns}
            dataGridProps={{
              columns,
              editMode: 'row',
              getRowId: (row: EnterpriseAddress) => row.addressBookID,
              loading: status === 'loading',
              onRowEditStart: handleRowEditStart,
              onRowEditStop: handleRowEditStop,
              onRowModesModelChange: (newModel) => setRowModesModel(newModel),
              processRowUpdate,
              rowModesModel,
              rows: data ?? [],
            }}
          />
        </Box>
      )}
    </>
  );
};
