import * as React from "react";
import { default as MuiTabs } from "@mui/material/Tabs";
import MuiTab from "@mui/material/Tab";
import Box from "@mui/material/Box";

export type TabsProps = {
  tabs: {
    label: string;
    component?: React.ReactNode;
  }[];
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

/**
 *
 * Core Tabs Component
 * Supports dynamic tabs via props.
 *
 */
export const Tabs = ({ tabs }: TabsProps) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const { labels, content } = React.useMemo(
    () => ({
      labels: tabs.map((tab, idx) => (
        <MuiTab key={idx} label={tab.label} {...a11yProps(idx)} />
      )),
      content: tabs.map((tab, idx) => (
        <TabPanel key={idx} value={value} index={idx}>
          {tab.component || null}
        </TabPanel>
      )),
    }),
    [tabs, value]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs
          value={value}
          onChange={handleChange}
          aria-label="core tabs example"
        >
          {labels}
        </MuiTabs>
      </Box>
      {content}
    </Box>
  );
};
