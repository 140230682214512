import { Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { Page } from '../../components/Page';

export const NotFound = () => <Page render={() => <Content />} />;
const Content = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Stack alignItems="center">
        <ErrorIcon
          sx={{
            fontSize: '80px',
            color: 'warning.light',
          }}
          fontSize="large"
        />
        <Typography variant="h5">Page Not Found</Typography>
      </Stack>
    </Box>
  );
};
