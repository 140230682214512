import { z } from "zod";

export const EnterpriseAddress = z.object({
  addressBookID: z.number(),
  addressLine1: z.string(),
  addressLine2: z.string().nullable(),
  city: z.string(),
  code: z.string().nullable(),
  companyName: z.string(),
  contactEmail: z.string().nullable(),
  contactName: z.string().nullable(),
  contactPhone: z.string().nullable(),
  countryCode: z.string(),
  enterpriseAddressCarriers: z.unknown(), // TODO
  enterpriseAddressServices: z.unknown(), // TODO
  enterpriseID: z.number(),
  fax: z.string().nullable(),
  isBypassTender: z.boolean(),
  isDefaultDestination: z.boolean(),
  isDefaultOrigin: z.boolean(),
  isFacility: z.boolean().nullable(),
  isResidential: z.boolean().nullable(),
  locationCode: z.string(),
  locationComments: z.string().nullable(),
  originPickUpEarliest: z.string().nullable(),
  originPickUpLatest: z.string().nullable(),
  owner: z.string(),
  postalCode: z.string(),
  refCarrierID: z.number(),
  stateProvince: z.string(),
});

export const EnterpriseAddressBook = EnterpriseAddress.array();

export type EnterpriseAddress = z.infer<typeof EnterpriseAddress>;
export type EnterpriseAddressBook = z.infer<typeof EnterpriseAddressBook>;