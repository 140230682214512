import { GridColDef } from "@mui/x-data-grid-premium";
import moment from "moment";

export const removeOrAddColumn = (columns: GridColDef[], column: any, localStorageName: string): GridColDef[] => {
    if (columns.some(c => c.field === column.propertyName)) {
        let updatedColumns = ensureActionsColumnIsLast(columns.filter(c => c.field !== column.propertyName));
        saveColumnsToStorage(updatedColumns, localStorageName);
        return updatedColumns;
    } else {
        let addedColumn: GridColDef = {
            field: column.propertyName,
            headerName: column.title,
            valueFormatter: column.isDate ? (params: any) => moment(params?.value).format("MM/DD/YYYY hh:mm A") : undefined,
            flex: 1
        };

        console.log('added column', addedColumn);

        let updatedColumns = ensureActionsColumnIsLast([...columns, addedColumn]);

        console.log('updated columns', updatedColumns);

        saveColumnsToStorage(updatedColumns, localStorageName);

        console.log(JSON.stringify(updatedColumns));

        return updatedColumns;
    }
}

export const reorderColumn = (columns: GridColDef[], columnPropertyName: string, targetIndex: number, localStorageName: string): GridColDef[] => {
    const currentIndex = columns.findIndex(c => c.field === columnPropertyName);
    if (columnPropertyName === 'actions' || currentIndex < 0 || targetIndex < 1) {
        return [...columns];
    }

    let updatedColumns = ensureActionsColumnIsLast(moveItemInArrayFromIndexToIndex(columns, currentIndex, targetIndex - 1));
    saveColumnsToStorage(updatedColumns, localStorageName);
    return updatedColumns;
}

const ensureActionsColumnIsLast = (columns: GridColDef[]): GridColDef[] => {
    const actionsElementIndex = columns.findIndex(e => e.field === 'actions');
    if (actionsElementIndex !== columns.length - 1) {
        return moveItemInArrayFromIndexToIndex(columns, actionsElementIndex, columns.length - 1);
    } else {
        return columns;
    }
}

const saveColumnsToStorage = (columns: GridColDef[], localStorageName: string) => {
    localStorage.setItem(localStorageName, JSON.stringify(columns));
}

const moveItemInArrayFromIndexToIndex = (array: GridColDef[], fromIndex: number, toIndex: number) => {
    if (fromIndex === toIndex) return array;

    const newArray = [...array];

    const target = newArray[fromIndex];
    const inc = toIndex < fromIndex ? -1 : 1;

    for (let i = fromIndex; i !== toIndex; i += inc) {
        newArray[i] = newArray[i + inc];
    }

    newArray[toIndex] = target;

    return newArray;
};