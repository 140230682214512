import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import {
  RefCarrierServiceLevel,
  RefCarrierServiceLevelTypes,
  useGetRefCarrierServiceLevels,
  useRefCarrierServiceLevelMutation,
} from '../../../../services/shipmentApi';
import { useStore } from '../../../../store';
import { SearchList } from '../../../../stories/List/SearchList';
import { Button } from '../../../../stories/Button';
import AddIcon from '@mui/icons-material/Add';
import { Clear, Save } from '@mui/icons-material';
import { LoadingButton } from '../../../../stories/LoadingButton';
import { SkeletonLoader } from '../../../../stories/SkeletonLoader';

const initialRefCarrierServiceLevel: RefCarrierServiceLevel = {
  serviceLevelName: '',
  type: null,
};

export const CarrierServiceLevelCard = () => {
  const { data: refCarrierServiceLevels, isLoading } =
    useGetRefCarrierServiceLevels();
  const refCarrierServiceLevelMutation = useRefCarrierServiceLevelMutation();
  const [showAddField, setShowAddField] = useState(false);
  const [newRefCarrierServiceLevel, setNewRefCarrierServiceLevel] =
    useState<RefCarrierServiceLevel>(initialRefCarrierServiceLevel);
  const [nameFieldError, setNameFieldError] = useState('');

  const setSnack = useStore((state) => state.setSnackbar);

  const refCarrierServiceLevelNames =
    refCarrierServiceLevels
      ?.map((sl) => sl.serviceLevelName)
      .sort((a, b) => {
        return a.localeCompare(b, undefined, { sensitivity: 'base' });
      }) || [];

  const handleShowServiceLevelField = () => {
    setShowAddField(!showAddField);
  };

  const handleSubmitServiceLevel = async () => {
    if (!newRefCarrierServiceLevel?.serviceLevelName) return;

    const isNameDupe = Boolean(
      refCarrierServiceLevelNames?.find(
        (serviceLevelName) =>
          serviceLevelName.localeCompare(
            newRefCarrierServiceLevel.serviceLevelName,
            undefined,
            { sensitivity: 'base' }
          ) === 0
      )
    );

    if (isNameDupe) {
      setNameFieldError('Service Level Names must be unique');
      return;
    }

    try {
      await refCarrierServiceLevelMutation.mutateAsync(
        newRefCarrierServiceLevel
      );
      setSnack({
        children: 'Carrier Service Level successfully saved',
        severity: 'success',
      });
      resetAddServiceLevel();
    } catch (e) {
      console.error(e); // TODO: Logging
      setSnack({
        children: `There was an error attempting to save new Carrier Service Level`,
        severity: 'error',
      });
    }
  };

  const resetAddServiceLevel = () => {
    setShowAddField(!showAddField);
    setNewRefCarrierServiceLevel(initialRefCarrierServiceLevel);
  };

  const resetNameFieldError = () => {
    setNameFieldError('');
  };

  return (
    <Card sx={{ width: 'fit-content' }}>
      <CardHeader title="Carrier Service Levels" />
      <CardContent>
        {isLoading ? (
          <>
            <SkeletonLoader variant="text" width={400} height={60} />
            <SkeletonLoader variant="rectangular" height={400} />
            <SkeletonLoader variant="text" height={40} />
          </>
        ) : (
          <>
            <SearchList data={refCarrierServiceLevelNames} width={400} />
            {showAddField && (
              <>
                <Divider sx={{ mt: 3, mb: 3 }} />
                <Stack direction="column">
                  <Typography sx={{ mb: 2 }} variant="h5">
                    New Service Level
                  </Typography>

                  <TextField
                    error={Boolean(nameFieldError)}
                    helperText={nameFieldError}
                    label="Name"
                    onChange={(e) => {
                      resetNameFieldError();
                      setNewRefCarrierServiceLevel({
                        ...newRefCarrierServiceLevel,
                        serviceLevelName: e.target.value,
                      });
                    }}
                    sx={{ mb: 3 }}
                  />

                  <FormControl sx={{ mb: 3 }}>
                    <InputLabel id="service-level-type-label">Type</InputLabel>
                    <Select
                      labelId="service-level-type-label"
                      id="service-level-type"
                      value={newRefCarrierServiceLevel.type ?? ''}
                      label="Type"
                      onChange={(e) => {
                        setNewRefCarrierServiceLevel({
                          ...newRefCarrierServiceLevel,
                          type: !e.target.value
                            ? null
                            : (e.target
                                .value as RefCarrierServiceLevel['type']),
                        });
                      }}
                    >
                      {[
                        <MenuItem key="none" value="">
                          <em>None</em>
                        </MenuItem>,
                      ].concat(
                        RefCarrierServiceLevelTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>

                  <Stack
                    alignItems="center"
                    direction="row"
                    justifyContent="space-between"
                  >
                    <LoadingButton
                      disabled={
                        !newRefCarrierServiceLevel.serviceLevelName ||
                        Boolean(nameFieldError)
                      }
                      isLoading={refCarrierServiceLevelMutation.isLoading}
                      label="Save Service Level"
                      onClick={handleSubmitServiceLevel}
                      startIcon={<Save />}
                    />
                    <Button
                      label="Cancel"
                      onClick={resetAddServiceLevel}
                      startIcon={<Clear />}
                      variant="outlined"
                    />
                  </Stack>
                </Stack>
              </>
            )}
          </>
        )}
      </CardContent>
      {!showAddField && (
        <CardActions>
          <Button
            label="Add Service Level"
            onClick={handleShowServiceLevelField}
            startIcon={<AddIcon />}
            variant="text"
          />
        </CardActions>
      )}
    </Card>
  );
};
