import { z } from 'zod';

const contact = z.object({
  cell: z.string().nullable(),
  contactID: z.number(),
  email: z.string().email().nullable(),
  fax: z.string().nullable(),
  name: z.string().min(1, 'Name must be at least one character'),
  phone: z.string().nullable(),
  title: z.string().nullable(),
});

const newContact = contact.omit({ contactID: true });

const contactType = z.object({
  refCarrierContactTypeID: z.number(),
  type: z.string(),
});

export const RefCarrierContact = z.object({
  contact,
  contactType,
  refCarrierContactID: z.number(),
});

export type RefCarrierContact = z.infer<typeof RefCarrierContact>;

export const NewRefCarrierContact = z.object({
  contact: newContact,
  contactType,
});

export type NewRefCarrierContact = z.infer<typeof NewRefCarrierContact>;
