export const Columns = [
    {
        title: 'Shipment ID',
        propertyName: 'shipmentID'
    },
    {
        title: 'Created Date',
        propertyName: 'createdDate',
        isDate: true
    },
    {
        title: 'User Created',
        propertyName: 'createdBy'
    },
    {
        title: 'Shipment Status',
        propertyName: 'shipmentStatus',
    },
    {
        title: 'Target Ship Early',
        propertyName: 'targetShipEarly',
        isDate: true
    },
    {
        title: 'Target Ship Range',
        propertyName: 'targetShipRange',
        isDateRange: true
    },
    {
        title: 'Primary Reference',
        propertyName: 'primaryReference'
    },
    {
        title: 'Owner',
        propertyName: 'owner'
    },
    {
        title: 'Weight',
        propertyName: 'weight'
    },
    {
        title: 'Weight UOM',
        propertyName: 'weightUOM'
    },
    {
        title: 'Carrier Name',
        propertyName: 'carrierName'
    },
    {
        title: 'SCAC',
        propertyName: 'scac'
    },
    {
        title: 'Quantity',
        propertyName: 'quantity'
    },
    {
        title: 'Handling Units',
        propertyName: 'handlingUnits'
    },
    {
        title: 'Carrier Mode',
        propertyName: 'carrierMode'
    },
    {
        title: 'Destination Name',
        propertyName: 'destinationName'
    },
    {
        title: 'Dest. Address 1',
        propertyName: 'destinationAddressLine1'
    },
    {
        title: 'Dest. Address 2',
        propertyName: 'destinationAddressLine2'
    },
    {
        title: 'Destination City',
        propertyName: 'destinationCity'
    },
    {
        title: 'Destination State',
        propertyName: 'destinationStateProvince'
    },
    {
        title: 'Dest. Postal Code',
        propertyName: 'destinationPostalCode'
    },
    {
        title: 'Origin Name',
        propertyName: 'originName'
    },
    {
        title: 'Origin Address 1',
        propertyName: 'originAddressLine1'
    },
    {
        title: 'Origin Address 2',
        propertyName: 'originAddressLine2'
    },
    {
        title: 'Origin City',
        propertyName: 'originCity'
    },
    {
        title: 'Origin State',
        propertyName: 'originStateProvince'
    },
    {
        title: 'Origin Postal Code',
        propertyName: 'originPostalCode'
    },
    {
        title: 'PRO Number',
        propertyName: 'proNumber'
    },
    {
        title: 'Customer Reference',
        propertyName: 'customerReference'
    },
    {
        title: 'Enterprise',
        propertyName: 'enterprise'
    },
    {
        title: 'Group Owner',
        propertyName: 'groupOwner'
    },
    {
        title: 'PRO',
        propertyName: 'pro'
    },
    {
        title: 'Pickup Number',
        propertyName: 'pickupNumber'
    },
    {
        title: 'Bill-To Name',
        propertyName: 'billToName'
    },
    {
        title: 'Carrier Charge',
        propertyName: 'carrierCharge'
    },
    {
        title: 'Customer Charge',
        propertyName: 'customerCharge'
    },
    {
        title: 'Margin',
        propertyName: 'margin'
    },
    {
        title: 'Sales Rep Department Group',
        propertyName: 'salesRepDepartmentGroup'
    },
    {
        title: 'Origin Terminal Phone Number',
        propertyName: 'originTerminalPhoneNumber'
    },
    {
        title: 'Reason Rejected Notes',
        propertyName: 'reasonRejectedNotes'
    }
]