import React from 'react';
import Button from '@mui/material/Button';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export interface ReleaseOrRetenderModalProps {
    open: boolean;
    retender: boolean;
    close: () => void;
    confirmReleaseOrTender: () => void;
}

export function ReleaseOrRetenderModal(props: ReleaseOrRetenderModalProps) {
    return (
        <Dialog
            open={props.open}
        >
                <DialogContent>
                    <DialogTitle id="alert-dialog-title">
                        { props.retender ? 'Re-tender selected shipments?' : 'Release these shipments for tender?'}
                    </DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {
                        props.retender ? 'Submitting this action will attempt to re-tender all of the selected shipments. ' : 'Submitting this action will release the selected shipments for Tender.'
                    }
                </DialogContentText>
                </DialogContent>
                </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={() => props.close()}>
                    Cancel
                </Button>
                <Button variant='outlined' onClick={() => props.confirmReleaseOrTender()}>
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
