import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../router';
import { useAuth, USER_ROLES } from '../../auth';
import { Tabs, TabsProps } from '../../stories/Tabs';
import { BypassConfig } from './TenderBypassConfig';
import { CarrierConfig } from './TenderCarrierConfig/TenderCarrierConfig';
import { ReferenceConfig } from './ReferenceConfig';

export const Admin = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const isAdmin = auth.userHasOperation(USER_ROLES.CAN_MANAGE_TENDER_CONFIG);

  if (!isAdmin) navigate(PATHS.HOME);

  const tabs: TabsProps['tabs'] = [
    {
      label: 'Reference Config',
      component: <ReferenceConfig />,
    },
    {
      label: 'Enterprise Config',
      component: <BypassConfig />,
    },
    {
      label: 'Carrier Config',
      component: <CarrierConfig />,
    },
  ];

  return !isAdmin ? null : <Tabs tabs={tabs} />;
};
