import { z } from 'zod';

export const RefCarrierServiceLevelTypes = [
  'ALL',
  'GUARANTEED',
  'STANDARD',
  'VOLUME',
] as const;

export const RefCarrierServiceLevel = z.object({
  refCarrierServiceLevelID: z.number().optional(),
  type: z.enum(RefCarrierServiceLevelTypes).nullable().optional(),
  serviceLevelName: z.string(),
});

export type RefCarrierServiceLevel = z.infer<typeof RefCarrierServiceLevel>;
