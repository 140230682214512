import { z } from 'zod';

export const RefCarrier = z.object({
    refCarrierID: z.number(),
    scac: z.string(),
    carrierName: z.string(),
    carrierNotes: z.string().nullable(),
    trackingURL: z.string().nullable(),
    isBlockInsurance: z.boolean(),
    rating: z.number().nullable(),
    isBlueShipTender: z.boolean(),
    isActive: z.boolean(),
    refModeId: z.number(),
    mode: z.string().nullable(),
    refModeOptionId: z.number(),
    modeOption: z.string().nullable()
  });

export type RefCarrier = z.infer<typeof RefCarrier>;