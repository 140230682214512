import { IconButton } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import { config } from "../../../utils/config";

type props = {
    shipmentId: number;
}

const BACKOFFICE_URL = config.backofficeUrl;

export const GridActionMenu = ({ shipmentId }: props) => {
    const handleEditShipmentClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      window.open(`${BACKOFFICE_URL}/shipment-edit-ltl/${shipmentId}`);
    };
    return (
      <div>
        <IconButton color='primary' title='Edit this shipment' onClick={(e) => handleEditShipmentClick(e)}>
          <EditIcon />
        </IconButton>
      </div>
    );
  };