import React from 'react';
import { debounce } from 'lodash';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import { Autocomplete, AutocompleteProps } from '../Autocomplete';

const SEARCH_DEBOUNCE_TIME = 300;

type AsyncTypeAheadProps<TOption> = AutocompleteProps<TOption, false> & {
  minimumSearchChar?: number;
  searchEndpoint: (searchString: string) => Promise<TOption[]>;
};

export const AsyncTypeAhead = <TOption extends any>({
  errorMessage,
  getOptionLabel,
  helperText = ' ',
  hidden,
  isOptionEqualToValue,
  label,
  minimumSearchChar = 2,
  onChange,
  searchEndpoint,
}: AsyncTypeAheadProps<TOption>) => {
  const [open, setOpen] = React.useState(false);
  const [serverOptions, setOptions] = React.useState<any[]>([]);
  const [isSearching, setIsSearching] = React.useState(false);

  const searchCache: Record<string, string[]> = {};
  const isLoading = open && isSearching;

  const performSearch = React.useCallback(
    debounce(async (inputValue: string, minimumSearchChar: number) => {
      if (inputValue.length <= minimumSearchChar) return;
      setIsSearching(true);
      const cachedSearch = searchCache[inputValue];
      const results =
        cachedSearch ??
        (await (searchEndpoint ? searchEndpoint(inputValue) : []));
      if (Array.isArray(results)) {
        searchCache[inputValue] = results;
        setOptions(results);
      }
      setIsSearching(false);
    }, SEARCH_DEBOUNCE_TIME),
    [searchEndpoint]
  );

  return !searchEndpoint ? null : (
    <Autocomplete<Awaited<ReturnType<typeof searchEndpoint>>[number], false>
      filterOptions={(x) => x}
      getOptionLabel={getOptionLabel}
      hidden={hidden}
      isOptionEqualToValue={isOptionEqualToValue}
      label={label}
      loading={isLoading}
      onChange={onChange}
      onInputChange={(event, newInputValue) => {
        // Bail on selection events to not needlessly call the search endpoint
        if (event?.type === 'click') return;
        if (event?.type === 'keydown' && (event as any)?.key === 'Enter')
          return;
        performSearch(newInputValue, minimumSearchChar);
      }}
      onClose={() => {
        setOpen(false);
        setOptions([]);
      }}
      onOpen={() => {
        setOpen(true);
      }}
      options={serverOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ m: 0 }}
          error={Boolean(errorMessage)}
          label={label}
          helperText={errorMessage ?? helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
