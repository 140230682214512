import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback, useEffect, useState } from 'react';
import {
  RefCarrierModeServiceOption,
  useGetRefCarrierModeServices,
  useGetRefCarrierServiceLevels,
  useGetRefModes,
  useAddRefCarrierModeServiceMutation,
  useDeleteRefCarrierModeServiceMutation,
} from '../../../../services/shipmentApi';
import { Button } from '../../../../stories/Button';
import { VirtualizedList } from '../../../../stories/List';
import { LoadingButton } from '../../../../stories/LoadingButton';
import { useStore } from '../../../../store';
import { CarrierServiceTenderList } from '../CarrierServiceTenderList';
import { SkeletonLoader } from '../../../../stories/SkeletonLoader';

type CarrierModeServiceListProps = {
  refCarrierId: number;
};

export const CarrierModeServiceList = ({
  refCarrierId,
}: CarrierModeServiceListProps) => {
  const { data: refCarrierServiceLevels = [] } =
    useGetRefCarrierServiceLevels();
  const { data: refModes = [] } = useGetRefModes();
  const { data: refCarrierModeServices, isLoading } =
    useGetRefCarrierModeServices(refCarrierId);
  const refCarrierModeServiceAddMutation =
    useAddRefCarrierModeServiceMutation(refCarrierId);
  const refCarrierModeServiceDeleteMutation =
    useDeleteRefCarrierModeServiceMutation(refCarrierId);
  const [selectedModeService, setSelectedModeService] =
    useState<RefCarrierModeServiceOption | null>();
  const [selectedModeServiceIndex, setSelectedModeServiceIndex] =
    useState<number | null>(null);
  const [showAddModeServiceForm, setShowAddModeServiceForm] = useState(false);
  const [newRefMode, setNewRefMode] = useState<number>();
  const [newServiceLevel, setNewServiceLevel] = useState<number>();
  const setSnack = useStore((state) => state.setSnackbar);
  const setDialog = useStore((state) => state.setDialog);
  const [dupeModeServiceError, setDupeModeServiceError] = useState<string>();

  const checkForDuplicateCombos = useCallback(() => {
    if (!newRefMode || !newServiceLevel) {
      setDupeModeServiceError(undefined);
      return;
    }

    const newModeServiceLabel = `${
      refModes.find((rm) => rm.refModeID === newRefMode)?.mode
    } - ${
      refCarrierServiceLevels.find(
        (rcsl) => rcsl.refCarrierServiceLevelID === newServiceLevel
      )?.serviceLevelName
    }`;

    const isDupe = Boolean(
      refCarrierModeServices?.find(
        (rcms) => rcms.modeService === newModeServiceLabel
      )
    );

    if (isDupe) {
      setDupeModeServiceError(
        `This carrier already has a ${newModeServiceLabel}`
      );
    } else {
      setDupeModeServiceError(undefined);
    }
  }, [
    newRefMode,
    newServiceLevel,
    refModes,
    refCarrierServiceLevels,
    refCarrierModeServices,
  ]);

  useEffect(() => {
    setSelectedModeService(null);
    setShowAddModeServiceForm(false);
  }, [refCarrierId]);

  useEffect(() => {
    checkForDuplicateCombos();
  }, [newRefMode, newServiceLevel, checkForDuplicateCombos]);

  const resetAddModeService = () => {
    setShowAddModeServiceForm(false);
    setNewRefMode(undefined);
    setNewServiceLevel(undefined);
    checkForDuplicateCombos();
  };

  const handleDeleteModeService = async () => {
    if (!selectedModeService)
      throw Error('Tried to delete without a selected mode-service');

    try {
      await refCarrierModeServiceDeleteMutation.mutateAsync(
        selectedModeService.refCarrierModeServiceID
      );
      setSnack({
        children: 'Mode - Service successfully deleted',
        severity: 'success',
      });

      setSelectedModeService(null);
    } catch (e) {
      console.error(e); // TODO: Logging
      setSnack({
        children: `There was an error attempting to delete selected Mode - Service`,
        severity: 'error',
      });
    }
  };

  const handleAddModeService = async () => {
    if (!newServiceLevel || !newRefMode)
      throw Error('Must have selected a service level and mode to save.');

    try {
      await refCarrierModeServiceAddMutation.mutateAsync({
        refCarrierServiceLevelID: newServiceLevel,
        refModeID: newRefMode,
      });
      setSnack({
        children: 'Mode - Service successfully added',
        severity: 'success',
      });

      resetAddModeService();
    } catch (e) {
      console.error(e); // TODO: Logging
      setSnack({
        children: `There was an error attempting to add Mode - Service mapping`,
        severity: 'error',
      });
    }
  };

  const confirmDelete = () => {
    setDialog({
      content: (
        <>
          Do you really want to delete{' '}
          <strong>{selectedModeService?.modeService}</strong> ?
        </>
      ),
      onCancel: () => {
        /* NOOP */
      },
      onConfirm: () => handleDeleteModeService(),
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <SkeletonLoader variant="text" width={200} height={40} />
          <SkeletonLoader variant="rectangular" height={200} />
          <SkeletonLoader variant="text" height={40} />
        </>
      ) : (
        <>
          <Typography variant="h5" gutterBottom>
            Mode - Service's
          </Typography>

          <Box sx={{ width: 400, bgcolor: 'hsl(0, 0%, 97%)' }}>
            <VirtualizedList
              data={refCarrierModeServices!}
              displayKey="modeService"
              onItemClick={(item, idx) => {
                setSelectedModeService(item);
                setSelectedModeServiceIndex(idx);
              }}
              selectedIndex={selectedModeServiceIndex}
              width={400}
              height={200}
            />
          </Box>

          {showAddModeServiceForm ? (
            <Box sx={{ mt: 3, width: 400 }}>
              <Stack direction="column">
                <Typography sx={{ mb: 2 }} variant="h5">
                  Add Mode - Service
                </Typography>

                <FormControl sx={{ mb: 3 }}>
                  <InputLabel id="mode-label">Mode</InputLabel>
                  <Select
                    error={Boolean(dupeModeServiceError)}
                    labelId="mode-label"
                    id="mode"
                    value={newRefMode ?? ''}
                    label="Mode"
                    onChange={(e) => {
                      if (typeof e.target.value === 'string') return;
                      setNewRefMode(e.target.value);
                    }}
                  >
                    {refModes.map(({ refModeID, mode }) => (
                      <MenuItem key={refModeID} value={refModeID}>
                        {mode}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={Boolean(dupeModeServiceError)}>
                    {dupeModeServiceError}
                  </FormHelperText>
                </FormControl>

                <FormControl sx={{ mb: 3 }}>
                  <InputLabel id="service-label">Service</InputLabel>
                  <Select
                    error={Boolean(dupeModeServiceError)}
                    labelId="service-label"
                    id="service"
                    value={newServiceLevel ?? ''}
                    label="Service"
                    onChange={(e) => {
                      if (typeof e.target.value === 'string') return;
                      setNewServiceLevel(e.target.value);
                    }}
                  >
                    {refCarrierServiceLevels.map(
                      ({ serviceLevelName, refCarrierServiceLevelID }) => (
                        <MenuItem
                          key={refCarrierServiceLevelID}
                          value={refCarrierServiceLevelID}
                        >
                          {serviceLevelName}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  <FormHelperText error={Boolean(dupeModeServiceError)}>
                    {dupeModeServiceError}
                  </FormHelperText>
                </FormControl>
                <Stack
                  alignItems="center"
                  direction="row"
                  justifyContent="space-between"
                >
                  <LoadingButton
                    disabled={
                      !newRefMode ||
                      !newServiceLevel ||
                      Boolean(dupeModeServiceError)
                    }
                    isLoading={refCarrierModeServiceAddMutation.isLoading}
                    label="Save Mode - Service"
                    onClick={handleAddModeService}
                    startIcon={<SaveIcon />}
                  />
                  <Button
                    label="Cancel"
                    onClick={resetAddModeService}
                    startIcon={<ClearIcon />}
                    variant="outlined"
                  />
                </Stack>
              </Stack>
            </Box>
          ) : (
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Button
                label="Add Mode - Service"
                onClick={() =>
                  setShowAddModeServiceForm(!showAddModeServiceForm)
                }
                startIcon={<AddIcon />}
                sx={{ mt: 1 }}
                variant="text"
              />
              <LoadingButton
                color="error"
                disabled={
                  !selectedModeService || refCarrierModeServices?.length === 1
                }
                isLoading={refCarrierModeServiceDeleteMutation.isLoading}
                label="Delete"
                onClick={confirmDelete}
                startIcon={<RemoveIcon />}
                sx={{ mt: 1 }}
                variant="text"
              />
            </Stack>
          )}
        </>
      )}
      {selectedModeService && (
        <CarrierServiceTenderList modeService={selectedModeService} />
      )}
    </>
  );
};
