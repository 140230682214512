import { Clear, Fax, Mail, Phone, Save, Smartphone } from '@mui/icons-material';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import {
  NewRefCarrierContact,
  RefCarrier,
  RefCarrierContact,
  RefCarrierContactType,
  useAddRefCarrierContactMutation,
  useUpdateRefCarrierContactMutation,
} from '../../../../services/shipmentApi';
import { useStore } from '../../../../store';
import { Button } from '../../../../stories/Button';
import { LoadingButton } from '../../../../stories/LoadingButton';

type CarrierContactFormProps = {
  carrierContactTypes: RefCarrierContactType[];
  onCancel?: () => void;
  onSave?: () => void;
  refCarrierContact?: RefCarrierContact;
  refCarrierId: RefCarrier['refCarrierID'];
};

const newRefCarrierContact: NewRefCarrierContact = {
  contact: {
    cell: null,
    email: null,
    fax: null,
    name: '',
    phone: null,
    title: null,
  },
  contactType: {
    type: '',
    refCarrierContactTypeID: 1,
  },
} as const;

const flattenZodErrors = (error: z.ZodError<RefCarrierContact>) => {
  const errors = error.format();
  return {
    name: errors.contact?.name?._errors,
    title: errors.contact?.title?._errors,
    phone: errors.contact?.phone?._errors,
    email: errors.contact?.email?._errors,
    cell: errors.contact?.cell?._errors,
    fax: errors.contact?.fax?._errors,
  };
};

export const CarrierContactForm = ({
  carrierContactTypes,
  onCancel,
  onSave,
  refCarrierContact,
  refCarrierId,
}: CarrierContactFormProps) => {
  const addRefCarrierContactMutation =
    useAddRefCarrierContactMutation(refCarrierId);
  const updateRefCarrierContactMutation =
    useUpdateRefCarrierContactMutation(refCarrierId);
  const [carrierContact, setCarrierContact] = useState<
    RefCarrierContact | NewRefCarrierContact
  >(refCarrierContact ?? newRefCarrierContact);
  const [fieldErrors, setFieldErrors] =
    useState<Record<string, string[] | undefined>>();
  const setSnack = useStore((state) => state.setSnackbar);

  useEffect(() => {
    if (refCarrierContact) setCarrierContact(refCarrierContact);
  }, [refCarrierContact]);

  const isUpdating =
    addRefCarrierContactMutation.isLoading ||
    updateRefCarrierContactMutation.isLoading;

  return (
    <>
      <List>
        <ListItem>
          <TextField
            disabled={isUpdating}
            error={Boolean(fieldErrors?.name)}
            helperText={fieldErrors?.name?.join('; ')}
            label="Name"
            onChange={(e) => {
              setCarrierContact({
                ...carrierContact,
                contact: {
                  ...carrierContact.contact,
                  name: e.target.value,
                },
              });
            }}
            size="small"
            sx={{ minWidth: '62%' }}
            value={carrierContact.contact.name ?? ''}
          />
        </ListItem>

        <ListItem>
          <TextField
            disabled={isUpdating}
            error={Boolean(fieldErrors?.title)}
            helperText={fieldErrors?.title?.join('; ')}
            label="Title"
            onChange={(e) => {
              setCarrierContact({
                ...carrierContact,
                contact: {
                  ...carrierContact.contact,
                  title: e.target.value || null,
                },
              });
            }}
            size="small"
            sx={{ minWidth: '62%' }}
            value={carrierContact.contact.title ?? ''}
          />
        </ListItem>

        <ListItem>
          <FormControl sx={{ minWidth: '62%' }}>
            <InputLabel id="contact-type-label">Contact Type</InputLabel>
            <Select
              disabled={isUpdating}
              labelId="contact-type-label"
              id="contact-type"
              label="Contact Type"
              onChange={(e) => {
                setCarrierContact({
                  ...carrierContact,
                  contactType:
                    carrierContactTypes[
                      (e.target.value as unknown as number) - 1
                    ],
                });
              }}
              size="small"
              value={carrierContact.contactType.refCarrierContactTypeID}
            >
              {carrierContactTypes.map((cct) => (
                <MenuItem
                  key={cct.refCarrierContactTypeID}
                  value={cct.refCarrierContactTypeID}
                >
                  {cct.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>

        <ListItem>
          <TextField
            disabled={isUpdating}
            error={Boolean(fieldErrors?.phone)}
            helperText={fieldErrors?.phone?.join('; ')}
            label="Phone"
            onChange={(e) => {
              setCarrierContact({
                ...carrierContact,
                contact: {
                  ...carrierContact.contact,
                  phone: e.target.value || null,
                },
              });
            }}
            value={carrierContact.contact.phone ?? ''}
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Phone />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>

        <ListItem>
          <TextField
            disabled={isUpdating}
            error={Boolean(fieldErrors?.cell)}
            helperText={fieldErrors?.cell?.join('; ')}
            label="Cell"
            onChange={(e) => {
              setCarrierContact({
                ...carrierContact,
                contact: {
                  ...carrierContact.contact,
                  cell: e.target.value || null,
                },
              });
            }}
            size="small"
            value={carrierContact.contact.cell ?? ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Smartphone />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>

        <ListItem>
          <TextField
            disabled={isUpdating}
            error={Boolean(fieldErrors?.fax)}
            helperText={fieldErrors?.fax?.join('; ')}
            label="Fax"
            onChange={(e) => {
              setCarrierContact({
                ...carrierContact,
                contact: {
                  ...carrierContact.contact,
                  fax: e.target.value || null,
                },
              });
            }}
            size="small"
            value={carrierContact.contact.fax ?? ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Fax />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>

        <ListItem>
          <TextField
            disabled={isUpdating}
            error={Boolean(fieldErrors?.email)}
            helperText={fieldErrors?.email?.join('; ')}
            label="Email"
            onChange={(e) => {
              setCarrierContact({
                ...carrierContact,
                contact: {
                  ...carrierContact.contact,
                  email: e.target.value || null,
                },
              });
            }}
            size="small"
            value={carrierContact.contact.email ?? ''}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Mail />
                </InputAdornment>
              ),
            }}
          />
        </ListItem>
      </List>

      <Stack alignItems="center" direction="row" justifyContent="space-between">
        <LoadingButton
          isLoading={isUpdating}
          label="Save"
          onClick={async () => {
            try {
              if (!(carrierContact as RefCarrierContact).refCarrierContactID) {
                const result = NewRefCarrierContact.parse(carrierContact);
                await addRefCarrierContactMutation.mutateAsync(result);
                setSnack({
                  children: 'Contact successfully added!',
                  severity: 'success',
                });
              } else {
                const result = RefCarrierContact.parse(carrierContact);
                await updateRefCarrierContactMutation.mutateAsync(result);
                setSnack({
                  children: 'Contact successfully updated!',
                  severity: 'success',
                });
              }
              if (onSave) onSave();
            } catch (e) {
              console.error(e); // TODO: Logging
              if (e instanceof z.ZodError) {
                setFieldErrors(flattenZodErrors(e));
              }
              setSnack({
                children: `There was an error attempting to add or update Contact`,
                severity: 'error',
              });
            }
          }}
          startIcon={<Save />}
          sx={{ mt: 1 }}
        />
        <Button
          disabled={isUpdating}
          label="Cancel"
          onClick={() => {
            setCarrierContact(refCarrierContact ?? newRefCarrierContact);
            if (onCancel) onCancel();
          }}
          startIcon={<Clear />}
          sx={{ mt: 1 }}
          variant="outlined"
        />
      </Stack>
    </>
  );
};
