import moment from "moment";
import { Columns } from "../models/columns"

export const columnDataFormatter = (columns: any) => {
    //based on field name, we should look up the field in our static columns and check to see if it is a date type
    columns.forEach((c: any) => {
        let column = Columns.find(x => x.propertyName === c.field);

        if (column?.isDate) {
            c.valueFormatter = (params: any) => moment.utc(params.value || params).local().format("MM/DD/YYYY hh:mm A")
        } else if (column?.isDateRange) {
            c.valueFormatter = (params: any) => {
                const range = params?.value ? params?.value?.split(" - ") : params ? params?.split(" - ") : null;
                if (range && range.length == 2) {
                    const rStart = range[0];
                    const rEnd = range[1];
                    if (moment(rStart).startOf('day').isSame(moment(rEnd).startOf('day'))) {
                        return `${moment.utc(rStart).local().format("MM/DD/YYYY hh:mm A")} - ${moment.utc(rEnd).local().format("hh:mm A")}`;
                    } else {
                        if (moment(rStart).year == moment(rEnd).year) {
                            return `${moment.utc(rStart).local().format("MM/DD hh:mm A")} - ${moment.utc(rEnd).local().format("MM/DD/YYYY hh:mm A")}`;
                        } else {
                            return `${moment.utc(rStart).local().format("MM/DD/YYYY hh:mm A")} - ${moment.utc(rEnd).local().format("MM/DD/YYYY hh:mm A")}`;
                        }
                    }
                }
                return params?.value;
            }
        }
    });

    return columns;
}