import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import {
  GridColDef,
  GridFilterModel,
  GridRowGroupingModel,
} from '@mui/x-data-grid-premium';
import { Fragment, ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  ColumnSelectionModal,
  ColumnSelectionModalProps,
} from './controls/modals/columnSelectionModal';
import CustomTenderDataGrid, {
  TenderDataGridProps,
} from './DataGrids/TenderBoardDataGrid';

import { makeStyles } from 'tss-react/mui';
import { RefreshButton } from './controls/buttons/refreshButton';
import { ResetFiltersButton } from './controls/buttons/resetFiltersButton';
import { ResetRowGroupsButton } from './controls/buttons/resetRowGroupsButton copy';

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    innerRoot: {
      width: '100%',
    },
    innerBox: {
      display: 'flex',
      margin: 0,
    },
    innerInnerBox: {
      display: 'flex',
      flexGrow: 1,
      padding: 1,
      margin: 0,
    },
    titleContainer: {},
    buttonContainer: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    divider: {
      margin: '5px',
    },
    button: {},
    backdrop: {
      color: '#fff',
    },
  };
});

export interface StandardBoardProps {
  title?: string; // Default Tender Board
  titleComponent?: ReactElement; // Tpography
  isColumnsButtonVisible?: boolean; // true
  onColumnButtonClick?: () => void;
  isColumnsDialogOpen?: boolean;
  onColumnsDialogClose?: () => void;
  onColumnsDialogChanged?: ColumnSelectionModalProps['onColumnChanged'];
  primaryElement?: ReactElement;
  secondaryElement?: ReactElement;
  isBackdropVisible?: boolean; // false;
  columns: GridColDef<any, any, any>[];
  children?: ReactNode;
  gridProps: TenderDataGridProps;
  classes?: ReturnType<typeof useStyles>['classes'];
  onRefreshButtonClick?: () => Promise<any>;
}

export function StandardBoard(props: StandardBoardProps) {
  const currentProps: StandardBoardProps = {
    title: 'Default Board',
    isColumnsButtonVisible: true,
    isBackdropVisible: false,
    ...props,
  };
  const { classes } = useStyles(undefined, {
    props: { classes: currentProps.classes! },
  });
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);
  const [isColumnsDialogOpenState, setIsColumnsDialogOpenState] = useState(
    currentProps.isColumnsDialogOpen || false
  );
  const isColumnsDialogOpen =
    currentProps.isColumnsDialogOpen !== undefined
      ? currentProps.isColumnsDialogOpen
      : isColumnsDialogOpenState;
  const emptyFilters: GridFilterModel = {
    items: [],
  };
  const [filters, setFilters] = useState<GridFilterModel>(emptyFilters);
  const [rowGrouping, setRowGrouping] = useState<GridRowGroupingModel>();
  const filterStorageObject = props?.gridProps?.filterStorageObject;
  const groupingStorageObject = props?.gridProps?.groupingStorageObject;

  useEffect(() => {
    function loadFilters() {
      if (filterStorageObject && localStorage.getItem(filterStorageObject)) {
        let obj = localStorage.getItem(filterStorageObject);
        if (obj) {
          let gfm = JSON.parse(obj) as GridFilterModel;
          setFilters(gfm);
        }
      }
    }

    loadFilters();
  }, [filterStorageObject]);

  useEffect(() => {
    if (groupingStorageObject) {
      const obj = localStorage.getItem(groupingStorageObject);
      if (obj) {
        const grouping = JSON.parse(obj) as GridRowGroupingModel;
        setRowGrouping(grouping);
      }
    }
  }, [groupingStorageObject]);

  function onColumnButtonClick() {
    if (props.onColumnButtonClick) {
      props.onColumnButtonClick();
    } else {
      setIsColumnsDialogOpenState(true);
    }
  }

  function onColumnsDialogClose() {
    if (props.onColumnsDialogClose) {
      props.onColumnsDialogClose();
    } else {
      setIsColumnsDialogOpenState(false);
    }
  }

  async function onRefreshButtonClick() {
    if (currentProps.onRefreshButtonClick) {
      setIsBackdropVisible(true);
      try {
        await currentProps.onRefreshButtonClick();
      } finally {
        setIsBackdropVisible(false);
      }
    }
  }

  function handleFilterModelChange(gfm: GridFilterModel) {
    let filterJson = JSON.stringify(gfm);
    if (filterStorageObject) {
      localStorage.setItem(filterStorageObject, filterJson);
    }

    setFilters(gfm);
  }

  function handleRowGroupingModelChange(grouping: GridRowGroupingModel) {
    setRowGrouping(grouping);
    if (groupingStorageObject) {
      localStorage.setItem(groupingStorageObject, JSON.stringify(grouping));
    }
  }

  const primaryElement = currentProps.primaryElement ? (
    <Fragment>
      <Divider
        className={classes.divider}
        style={{ margin: '5px' }}
        orientation="vertical"
      />
      {currentProps.primaryElement}
    </Fragment>
  ) : undefined;

  const secondaryElement =
    currentProps.secondaryElement || currentProps.onRefreshButtonClick ? (
      <Fragment>
        <Divider
          className={classes.divider}
          style={{ margin: '5px' }}
          orientation="vertical"
        />
        {filters && filters.items && filters.items.length > 0 ? (
          <ResetFiltersButton
            title="Reset all filters."
            onClick={() => handleFilterModelChange(emptyFilters)}
          />
        ) : undefined}
        {rowGrouping && rowGrouping.length > 0 ? (
          <ResetRowGroupsButton
            title="Reset all row grouping."
            onClick={() => handleRowGroupingModelChange([])}
          />
        ) : undefined}
        {currentProps.secondaryElement ? (
          currentProps.secondaryElement
        ) : currentProps.onRefreshButtonClick ? (
          <RefreshButton
            title="Refresh the current report list"
            onClick={onRefreshButtonClick}
          />
        ) : undefined}
      </Fragment>
    ) : undefined;

  return (
    <div className={classes.root}>
      <div className={classes.innerRoot}>
        <Box className={classes.innerBox}>
          <Box className={classes.innerInnerBox}>
            <div className={classes.titleContainer}>
              {currentProps.titleComponent ? (
                currentProps.titleComponent
              ) : (
                <Typography variant="h5" data-qa="board-title">
                  {currentProps.title}
                </Typography>
              )}
            </div>
          </Box>
          <Box className={classes.buttonContainer}>
            {currentProps.isColumnsButtonVisible ? (
              <Button
                data-qa="button-columns"
                className={classes.button}
                onClick={onColumnButtonClick}
              >
                Columns
              </Button>
            ) : undefined}
            {primaryElement}
            {secondaryElement}
          </Box>
        </Box>
      </div>
      <CustomTenderDataGrid
        {...currentProps.gridProps}
        columns={currentProps.columns}
        filterModel={filters}
        rowGroupingModel={rowGrouping}
        onFilterModelChange={handleFilterModelChange}
        onRowGroupingModelChange={handleRowGroupingModelChange}
      />
      <ColumnSelectionModal
        open={!!isColumnsDialogOpen}
        currentColumns={currentProps.columns}
        onClose={onColumnsDialogClose}
        onColumnChanged={props.onColumnsDialogChanged || (() => {})}
      />
      {currentProps.children}
      <Backdrop
        className={classes.backdrop}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBackdropVisible || !!currentProps.isBackdropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
