import { z } from 'zod';

export const TenderTypes = {
  EDI: 'EDI',
  Email: 'Email',
  P44: 'P44',
  Manual: 'Manual',
  None: 'None',
} as const;

export const TenderTypeList = Object.values(TenderTypes);

const BaseTender = z.object({
  isAutoAccept: z.boolean(),
  refCarrierTenderID: z.number(),
});

const EDITender = BaseTender.merge(
  z.object({
    type: z.literal(TenderTypes.EDI),
    connectionAddress: z.string().trim().min(1, 'Required'),
    username: z.string().trim().min(1, 'Required'),
    password: z.string().trim().min(1, 'Required'),
  })
);

const EmailTender = BaseTender.merge(
  z.object({
    type: z.literal(TenderTypes.Email),
    connectionAddress: z.string().email(),
  })
);

const P44Tender = BaseTender.merge(
  z.object({
    type: z.literal(TenderTypes.P44),
  })
);

const ManualTender = BaseTender.merge(
  z.object({
    type: z.literal(TenderTypes.Manual),
  })
);

const NoneTender = BaseTender.merge(
  z.object({
    type: z.literal(TenderTypes.None),
  })
);

export const RefCarrierTender = z.discriminatedUnion('type', [
  EDITender,
  EmailTender,
  P44Tender,
  ManualTender,
  NoneTender,
]);

export type RefCarrierTender = z.infer<typeof RefCarrierTender>;

const neverID = z.object({ refCarrierTenderID: z.never().optional() });

export const NewRefCarrierTender = z.discriminatedUnion('type', [
  EDITender.merge(neverID),
  EmailTender.merge(neverID),
  P44Tender.merge(neverID),
  ManualTender.merge(neverID),
  NoneTender.merge(neverID),
]);

export type NewRefCarrierTender = z.infer<typeof NewRefCarrierTender>;
