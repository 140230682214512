import { create } from 'zustand';
import {
  SnackbarSlice,
  createSnackbarSlice,
} from '../components/GlobalSnackbar';
import { DialogSlice, createDialogSlice } from '../components/GlobalDialog';
/**
 * WAIT!
 * Before adding state here. Please consider the following for your state needs:
 *   1. Local Component (React) State
 *   2. (React) Context - if it's state bound to your component tree's markup
 *   3. A module-level store
 *
 * Does the entire app really need to be aware of the state you intend to put here?
 * If not, it probably doesn't belong here...
 */

export const useStore = create<DialogSlice & SnackbarSlice>()((...state) => ({
  ...createDialogSlice(...state),
  ...createSnackbarSlice(...state),
}));
