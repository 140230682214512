import { actionsDisabled } from '../../utils/actionsDisabled';
import { DefaultColumns } from './data';
import { fetchTendersByStatus, releaseOrReTender } from '../../services/shipmentApi';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ReleaseOrRetenderModal } from '../../components/controls/modals/releaseOrRetenderModal';
import { ReleaseTenderButton } from '../../components/controls/buttons/releaseTenderButton';
import { removeOrAddColumn, reorderColumn } from '../../utils/columnReorder';
import { StandardBoard } from '../../components/standardBoard';
import { Tender } from '../../models/tender';
import { useAuth } from '../../auth';
import { useCallback, useEffect, useState } from 'react';
import { columnDataFormatter } from '../../utils/columnDataFormatter';

export function TenderCancelledBoard() {
    const columnsStorageKey = 'tenderCancelledBoardColumns';

    useEffect(() => {
        if (localStorage.getItem(columnsStorageKey)) {
            let columnObj = localStorage.getItem(columnsStorageKey);
            let obj = columnObj && JSON.parse(columnObj);

            if (obj) {
                let formattedColumns = columnDataFormatter(obj);
                setColumns(formattedColumns);
            }
        }
    }, [])

    const auth = useAuth();

    const [isLoading, setIsLoading] = useState(true);
    const [tenders, setTenders] = useState<Tender[]>([]);
    const [selectedShipments, setSelectedShipments] = useState<number[]>([]);
    const [isColumnsDialogOpen, setColumnsOpen] = useState(false);
    const [retenderModalOpen, setRetenderModalOpen] = useState(false);
    const [columns, setColumns] = useState<GridColDef[]>(DefaultColumns);

    const fetchData = useCallback(async () => {
        const data = await fetchTendersByStatus('TenderCancelled');
        setTenders(data);
        setIsLoading(false);
    }, []);

    const onColumnAddedRemoved = (column: any) => {
        let updatedColumns = removeOrAddColumn(columns, column, columnsStorageKey);
        setColumns(updatedColumns);
    }

    const onColumnIndexChange = (columnPropertyName: string, targetIndex: number) => {
        let updatedColumns = reorderColumn(columns, columnPropertyName, targetIndex, columnsStorageKey);
        setColumns(updatedColumns);
    }

    const retender = async () => {
        setRetenderModalOpen(false);
        setIsLoading(true);
        await releaseOrReTender(selectedShipments);
        await fetchData();
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData()
            .catch(console.error);;
    }, [fetchData])

    function onColumnsDialogClose() {
        setColumnsOpen(false);
        setRetenderModalOpen(false);
    }

    return (
        <StandardBoard
            onRefreshButtonClick={fetchData}
            title='Cancelled Tenders Board'
            primaryElement={<ReleaseTenderButton disabled={actionsDisabled(selectedShipments)} isRetender={true} title='Queue the selected shipments for re-tender' onClick={() => setRetenderModalOpen(true)} />}            
            gridProps={{
                filterStorageObject: 'cancelledBoardFilters',
                groupingStorageObject: 'cancelledBoardGrouping',
                setSelectedShipments: setSelectedShipments,
                tenders: tenders,
                onColumnIndexChange,
            }}
            columns={columns}
            isColumnsDialogOpen={isColumnsDialogOpen}
            onColumnsDialogChanged={onColumnAddedRemoved}
            onColumnsDialogClose={onColumnsDialogClose}
            isBackdropVisible={isLoading}
            onColumnButtonClick={() => setColumnsOpen(true)}
        >
            <ReleaseOrRetenderModal open={retenderModalOpen} retender={true} close={onColumnsDialogClose} confirmReleaseOrTender={retender} />
        </StandardBoard>
    );
}