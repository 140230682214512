import { z } from 'zod';
import { RefCarrierContact } from './refCarrierContact.model';

export const RefCarrierContactComment = z.object({
  commentID: z.number(),
  parentID: RefCarrierContact.shape.refCarrierContactID,
  userCreated: z.string(),
  value: z.string().min(1, 'Comment must be at least one character'),
});

export const NewRefCarrierContactComment = RefCarrierContactComment.omit({
  commentID: true,
  parentID: true,
  userCreated: true,
});

export type RefCarrierContactComment = z.infer<typeof RefCarrierContactComment>;
export type NewRefCarrierContactComment = z.infer<
  typeof NewRefCarrierContactComment
>;
