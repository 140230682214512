import { Typography } from '@mui/material';
import { CarrierServiceLevelCard } from './CarrierServiceLevelCard';

type ReferenceConfigProps = {};

export const ReferenceConfig = () => {
  return (
    <>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Reference Data Configuration
      </Typography>

      <CarrierServiceLevelCard />
    </>
  );
};
