import { RefCarrier, useGetRefCarrier } from '../../../services/shipmentApi';
import { Autocomplete, AutocompleteProps } from '../../../stories/Autocomplete';

type RefCarrierSelectFieldProps = {
    errorMessage?: string;
    helperText?: string;
    label?: string;
    onChange: Parameters<typeof Autocomplete<RefCarrier, false>>[0]['onChange'];
    InputProps?: AutocompleteProps<any, any>['InputProps'];
}

export const RefCarrierSelectField = ({
  errorMessage,
  helperText,
  label,
  onChange,
  InputProps,
}: RefCarrierSelectFieldProps) => {
  const { data: refCarriers, isLoading } = useGetRefCarrier();

  return (
    <Autocomplete<RefCarrier, false>
      errorMessage={errorMessage}
      getOptionLabel={(option) => {
        if (!option) return '';
        return `${option.scac} - ${option.carrierName}`;
      }}
      helperText={helperText}
      isOptionEqualToValue={(option, value) => {
        // Known @MUI issue: Waring: The value provided to Autocomplete is invalid. None of the options match.
        // @see: https://github.com/mui/material-ui/issues/29727
        return value.refCarrierID === option.refCarrierID;
      }}
      label={label ?? "Search"}
      loading={isLoading}
      onChange={onChange}
      options={refCarriers}
      InputProps={InputProps}
    />
  )
};
