import { useState, useMemo } from 'react';
import { TextField, Box } from '@mui/material';
import { VirtualizedList, VirtualizedListProps } from '../VirtualizedList';

// TODO: Move to helpers somewhere
const isStringArray = <T,>(value: T[] | string[]): value is string[] => {
  return typeof value[0] === 'string';
};

export type SearchListProps<TData> = VirtualizedListProps<TData> & {};

export const SearchList = <TData,>({
  data,
  displayKey,
  width = 1,
  ...props
}: SearchListProps<TData>) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredData = useMemo(() => {
    const filteredItems = isStringArray(data)
      ? data.filter((item) =>
          item.toLowerCase().trim().includes(searchValue.toLowerCase().trim())
        )
      : displayKey
      ? (data.filter((item) =>
          // These casts fix what smells like a TS bug or limitation. Need to investigate further...
          (item[displayKey!] as string)
            .toLowerCase()
            .trim()
            .includes(searchValue.toLowerCase().trim())
        ) as unknown as TData)
      : data;
    return filteredItems;
  }, [data, displayKey, searchValue]);

  return (
    <>
      <TextField
        label="Search"
        onChange={(e) => setSearchValue(e.target.value.trim())}
        sx={{ width, paddingBottom: 2 }}
      />
      <Box sx={{ width, bgcolor: 'hsl(0, 0%, 97%)' }}>
        {/**
         * TODO: Investigation pending
         * https://www.typescriptlang.org/play?#code/JYWwDg9gTgLgBAJQKYEMDG8BmUIjgcilQ3wG4AocmATzCTgGUYpgA7AcwGklqBnAHgAqAPjgBeOAG9ycOAG1OcNnADWPCJjiCAugC4tC7XCQAPGElYATXnF7M27OAH44i-ayQA3JFAoBfOTVqDS1tCipaegAhFF4kAAUcMBsJaVkAd2BLGAALJ3cAVxAAIx8KWRykYHYcmHy4ViLS33I-Sho6RnsOADUfXmAIVnE4GLjEiGS4ADIpGThLFBgUfTsWDjkw+ctgXjAAGxRqbmp3LzLW9si4AHligCskDD6oAaGhABEllFEJMYSkjZZmkFt99PFgGgVJ9vgAaLrrLg8ASCL7LYTCTblBa7A5HE76JiIk4otE-S4RToAYVwxQgMOWI08ECyvy0ZOMZgs1kQT2gln4awc8IKrBUrAg6VYohcd0ez36g1YDJ+cEJ3XYLzerHCaCGdlGsSQNPAQws8AkKthwgAFGBAfoTXSVcIAJTiUQ2+b8YTFI22GAFTCYfgAemE5FdV06AHUoCgwHQoBNki6Rk76ajvqJgX5wqHQ3AAJKaYAwfA2FD7fZwe4FA17IgoSxwXI4Ao1eFluDpaAqXhOch61gGmN9hxxhNJkZW232ya8fSTxM+FOk7PusSiEFEQNQYb8f4myAeVgwNOSAB01-nyT8cHDFILcBpZ5Y3hbAFETChwPt6AAtHAy7TnqpqnvAuy1vW8C8Ps1Q5Ps1BwCAEA7Jg1AOHA86eFkSAtp4VYFEgNilJg0D0GAsQDBwraVNhSRwEMQ76vAIE+MeZpnjOWbLNadoOsB8YrsmgIupu27zMOBqSD2WS5HA94SLevDYtJ8AgNQMbyTkIyZNkOQAIRwAA1HAACM2K7gU+5wF6siyPwOyeAG1D-mIkiSPpuT6Jp2kGX4fgRg5DmHkanEQRe16Xip97eTkHl+Tp96PiFYbOcFcBRm05DPp+3jDHWBqWCRzAFBgNlYZYEAkaw+CwUgSB4DAEA9n20UsSO8AfKVUDlXuE7CUmEXmjxZL8bJ8XwtFsVLkNq5ibxPwSXMsjWbZ9mhc5rnuZ58WBZlaVHrgJ7mmm8UeftUgzYCKWHXA6XAJ4mXZeQQA
         * @ts-ignore */}
        <VirtualizedList<TData>
          {...props}
          data={filteredData}
          displayKey={displayKey}
          width={width}
        />
      </Box>
    </>
  );
};
